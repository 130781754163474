import styled from 'styled-components'
import colors from '../../assets/style/colors'

const ButtonStyle = styled.div`
  .btn {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: ${colors.mainColor};
    color: #ffffff;
    font-size: 15px;
    border: none;
    min-width: 50px;
    height: 40px;
    cursor: pointer;
  }

  .btn:disabled {
    /* Styles for disabled state */
    opacity: 0.6; /* Example opacity */
    cursor: not-allowed; /* Example cursor */
  }

  .btn:hover {
    background-color: ${colors.mainColor};
  }

  .btn2 {
    padding: 10px 30px;
    border-radius: 5px;
    background-color: ${colors.mainColor};
    color: #ffffff;
    font-size: 15px;
    border: none;
    min-width: 50px;
    height: 40px;
  }
`
export default ButtonStyle
