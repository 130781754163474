import React, { useEffect, useState } from 'react'
import WarningModalStyle from './WarningModalStyle'
import WarningImage from '../../../assets/images/warning.jpg'
import Modal from 'react-modal'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 15,
    border: 'none',
  },
  overlay: {
    background: '#00000099',
  },
}

const InfoNotEnded = ({ getRef = () => {}, onConfirm = () => {} }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [title, setTitle] = useState(
    'При создании графика платежей и перед добавлением в него платежа вводите всю информацию полностью',
  )

  useEffect(() => {
    const ref = {
      open: (t) => {
        setIsOpenModal(true)
        setTitle(t)
      },
      close: () => setIsOpenModal(false),
    }
    getRef(ref)
  }, [])

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => setIsOpenModal(false)}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <WarningModalStyle>
        <div className="card">
          <div className="flex justify-center">
            <img src={WarningImage} alt="error image" className="img" />
          </div>
          <h1>{'Предупреждение'}</h1>
          <p className="title">
            {title}
            {'!'}
          </p>

          <div className="centerCard">
            <button
              className="btnN"
              onClick={() => {
                onConfirm()
                setIsOpenModal(false)
              }}
            >
              {'Понятно'}
            </button>
          </div>
        </div>
      </WarningModalStyle>
    </Modal>
  )
}

export default InfoNotEnded
