import React, { memo, useEffect, useState } from 'react'
import CreateUserStyle from './CreateUserStyle'
import Modal from 'react-modal'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 15,
    border: 'none',
  },
  overlay: {
    background: '#00000099',
  },
}

const CreateProductModal = ({
  getRef = () => {},
  onConfirm = () => {},
  onClose = () => {},
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [form, setForm] = useState({
    ItemName: '',
    U_Color: '',
    U_Condition: '',
  })

  useEffect(() => {
    const ref = {
      open: () => setIsOpenModal(true),
      close: () => setIsOpenModal(false),
    }
    getRef(ref)
  }, [])

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => setIsOpenModal(false)}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <CreateUserStyle>
        <div className="card">
          <h2>{'Создать продукт'}</h2>
          <input
            type="text"
            placeholder="Название"
            className="input"
            onChange={(v) =>
              setForm((prevState) => ({
                ...prevState,
                ItemName: v.target.value,
              }))
            }
          />
          <input
            type="text"
            placeholder="Цвет"
            className="input"
            onChange={(v) =>
              setForm((prevState) => ({
                ...prevState,
                U_Color: v.target.value,
              }))
            }
          />
          <input
            type="text"
            placeholder="Статус"
            className="input"
            onChange={(v) =>
              setForm((prevState) => ({
                ...prevState,
                U_Condition: v.target.value,
              }))
            }
          />

          <div className="centerCard">
            <button
              className="btnN"
              onClick={() => {
                onClose()
                setIsOpenModal(false)
              }}
            >
              {'Нет'}
            </button>
            <button
              className="btnY"
              disabled={
                form.ItemName === '' ||
                form.U_Color === '' ||
                form.U_Condition === ''
              }
              onClick={() => {
                onConfirm(form)
              }}
            >
              {'Да'}
            </button>
          </div>
        </div>
      </CreateUserStyle>
    </Modal>
  )
}

export default memo(CreateProductModal)
