import React, { useEffect, useRef, useState } from 'react'
import OutgoingPayment from './OutgoingPayment'
import Button from '../../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi } from '../../../api'
import _, { get } from 'lodash'
import Layout from '../../../components/Layout'
import { ErrorModal } from '../../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import moment from 'moment'

const OutgoingPaymentPage = () => {
  const navigation = useNavigate()
  const errorRef = useRef()

  const [isLoading, setIsLoading] = useState(false)
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split('T')[0],
  )
  const [pagination, setPagination] = useState({
    page: 0,
  })
  const [data, setData] = useState({
    data: [],
  })
  const search = (value) => {
    setIsLoading(true)
    api
      .get(
        `/VendorPayments?%24select=DocEntry,DocNum,DocType,CardCode,CardName,CashAccount,DocCurrency,CashSum,ControlAccount,DocDate,CashSumFC&$filter=${value?.cardCode !== undefined ? `CardCode eq '${value?.cardCode}' and ` : ''}${value?.cardName !== undefined ? `CardName eq '${value?.cardName}' and ` : ''}DocDate ge '${startDate + ' 00:00:00.000Z'}'&$skip=${pagination.page * 20}`,
        {
          headers: {
            Prefer: 'odata.maxpagesize=20',
          },
        },
      )
      .then((res) => {
        const resData = JSON.parse(res.data).value
        setData({
          data: [...resData],
        })
        setIsLoading(false)
      })
      .catch((err) => {
        if (isAxiosError(err))
          errorRef.current?.open(
            get(JSON.parse(err.response.data), 'error.message', ''),
          )
        setIsLoading(false)
      })
  }

  useEffect(() => {
    search()
  }, [startDate, pagination])

  const newDatas = () => {
    if (data.data.length < 20) {
      alert('Никакой другой информации')
    } else {
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
    }
  }

  const oldData = () => {
    if (pagination.page > 0) {
      setPagination((prev) => ({ ...prev, page: prev.page - 1 }))
    } else {
      alert('Никакой другой информации')
    }
  }

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value)
  }

  const handleCardCodeChange = _.debounce((e) => {
    search({ cardCode: e.target.value })
  }, 1000)

  const handleCardNameChange = _.debounce((e) => {
    search({ cardName: e.target.value })
  }, 1000)

  return (
    <Layout>
      <OutgoingPayment>
        <div className="container">
          <p className="productTitle">Исходящий платежи</p>
          <div className="searchCard mt-8">
            <div>
              <p className={'font-medium text-zinc-600 text-sm mb-1'}>
                CardCode
              </p>
              <input
                className={
                  'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full'
                }
                type="text"
                onChange={handleCardCodeChange}
              />
            </div>
            <div>
              <p className={'font-medium text-zinc-600 text-sm mb-1'}>
                CardName
              </p>
              <input
                className={
                  'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full'
                }
                type="text"
                onChange={handleCardNameChange}
              />
            </div>
            <div>
              <p className={'font-medium text-zinc-600 text-sm mb-1'}>
                Дата начала
              </p>
              <input
                type="date"
                className={
                  'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full'
                }
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
          </div>

          {isLoading ? (
            <ClipLoader
              loading={true}
              size={75}
              aria-label="Loading Spinner"
              className={'loader'}
              data-testid="loader"
            />
          ) : (
            <>
              <div className="overflow-y-auto mb-8">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                      <th scope="col" className="px-6 py-4">
                        Номер документа
                      </th>
                      <th scope="col" className="px-6 py-4">
                        CardName
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Баланс
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Валюта
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Сумма
                      </th>
                      <th scope="col" className="px-6 py-4">
                        ControlAccount
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Дата
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.data.map((v, i) => {
                      return (
                        <tr
                          className="bg-white border-b  hover:bg-gray-50 "
                          key={i}
                        >
                          <td className="px-6 py-4 font-medium text-gray-900 ">
                            {get(v, 'DocNum', '')}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {get(v, 'CardName', '')}
                          </td>
                          <td className="px-6 py-4">
                            {get(v, 'CashAccount', '')}
                          </td>
                          <td className="px-6 py-4">
                            {get(v, 'DocCurrency', '')}
                          </td>
                          <td className="px-6 py-4">
                            {get(v, 'DocCurrency', '') !== 'UZS'
                              ? get(v, 'CashSum', '')
                              : get(v, 'CashSumFC', '')}
                          </td>
                          <td className="px-6 py-4">
                            {get(v, 'CardCode', '')}
                          </td>
                          <td className="px-6 py-4">
                            {moment(get(v, 'DocDate', '')).format('DD-MM-YYYY')}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <div className="topCard">
                <div style={{ alignItems: 'center' }} className="flex">
                  <Button onClick={oldData} btnStyle={{ marginRight: 10 }}>
                    {'<'}
                  </Button>
                  <p style={{ marginRight: '10px' }}>{pagination.page + 1}</p>
                  <Button onClick={newDatas}>{'>'}</Button>
                </div>
                <div
                  className="btn-box"
                  style={{ display: 'flex', gap: '15px' }}
                >
                  <Button
                    btnStyle={{ width: 'max-content', padding: '0 10px' }}
                    onClick={() => navigation('/pay-supplier')}
                  >
                    Оплата поставщику
                  </Button>
                  <Button
                    btnStyle={{ width: 'max-content', padding: '0 10px' }}
                    onClick={() => navigation('/constant-consumption')}
                  >
                    Постоянные расход
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </OutgoingPayment>
      <>
        <ErrorModal
          getRef={(r) => {
            errorRef.current = r
          }}
        />
      </>
    </Layout>
  )
}

export default OutgoingPaymentPage
