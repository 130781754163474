import React from 'react'
import ButtonStyle from './ButtonStyle'
import ClipLoader from 'react-spinners/ClipLoader'

const Button = ({
  isLoading,
  children,
  onClick,
  btnStyle,
  hoverBtnStyle,
  disabled = false,
  className,
}) => {
  return (
    <ButtonStyle>
      <button
        disabled={isLoading || disabled}
        type={'button'}
        onClick={onClick}
        className={'btn p-4 ' + className}
        style={btnStyle}
      >
        {isLoading && (
          <ClipLoader
            loading={true}
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
        {children}
      </button>
    </ButtonStyle>
  )
}

export default Button
