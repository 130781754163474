import React, { memo, useEffect, useState } from 'react'
import PaymentModalStyle from './PaymentModalStyle'
import { AiFillDollarCircle, AiFillCreditCard } from 'react-icons/ai'
import api from '../../../api'
import numberWithSpaces from '../../../helpers/numberWithSpaces'
import Modal from 'react-modal'
import moment from 'moment'
import { get } from 'lodash'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 15,
    border: 'none',
  },
  overlay: {
    background: '#00000099',
  },
}

const PaymentModal = ({
  getRef = () => {},
  onConfirm = () => {},
  onClose = () => {},
}) => {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1 and pad with '0' if needed
  const day = String(currentDate.getDate()).padStart(2, '0')
  const formattedDate = `${year}-${month}-${day}`

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [sum, setSum] = useState('')
  const [valuta, setValuta] = useState('USD')
  const [paym, setPaym] = useState('U_CashAccount')
  const [dollar, setDollar] = useState('')
  const [dateC, setDateC] = useState(formattedDate)

  const [typeOfPayment, setTypeOfPayment] = useState([
    {
      title: 'Наличные',
      icon: <AiFillDollarCircle />,
      isActive: true,
      value: 'U_CashAccount',
    },
    {
      title: 'Карта',
      icon: <AiFillCreditCard />,
      isActive: false,
      value: 'U_CardAccount',
    },
  ])

  const [valuteDate, setValuteDate] = useState([
    {
      title: 'Доллар',
      value: 'USD',
      isActive: true,
    },
    {
      title: 'Сум',
      value: 'UZS',
      isActive: false,
    },
  ])

  useEffect(() => {
    const ref = {
      open: () => setIsOpenModal(true),
      close: () => setIsOpenModal(false),
    }
    getRef(ref)
  }, [])

  const sending = () => {
    if (!dollar) {
      alert('Курс не выбран')
    } else {
      if (paym === 'U_CardAccount' || paym === 'U_TransAccount') {
        onConfirm(sum, paym, 'UZS', dollar, account)
        setSum('')
      } else {
        onConfirm(sum, paym, valuta, dollar)
        setSum('')
      }
      setIsOpenModal(false)
    }
  }

  const changeActive = (v, index) => {
    setPaym(v)
    let a = [...typeOfPayment]

    for (let i = 0; i < a.length; i++) {
      if (i === index) {
        a[i].isActive = true
      } else {
        a[i].isActive = false
      }
    }

    setTypeOfPayment(a)
  }

  const changeActive2 = (v, index) => {
    setValuta(v)
    let a = [...valuteDate]

    for (let i = 0; i < a.length; i++) {
      if (i === index) {
        a[i].isActive = true
      } else {
        a[i].isActive = false
      }
    }

    setValuteDate(a)
  }

  const getUSD = (courseDate) => {
    if (courseDate) setDateC(courseDate)
    api
      .post(`SBOBobService_GetCurrencyRate`, {
        Currency: 'UZS',
        Date: courseDate || dateC,
      })
      .then((res) => {
        setDollar(JSON.parse(res.data))
      })
      .catch((err) => {
        alert('Курс не загружен. Пожалуйста, введите курс')
      })
  }

  useEffect(() => {
    getUSD()
  }, [])

  const [account, setAccount] = useState(null)
  const [accountsData, setAccountsData] = useState([])
  useEffect(() => {
    api
      .get(
        "ChartOfAccounts?$filter=startswith(Code, '57') and AccountLevel eq 3 and ExternalCode eq '1'&$select=Code,Name,AcctCurrency",
      )
      .then((res) => {
        setAccountsData(JSON.parse(res.data).value)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => {
        setIsOpenModal(false)
        setDateC(moment().format('YYYY-MM-DD'))
      }}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <PaymentModalStyle>
        <div className="card">
          <h2 className="text-lg font-bold text-zinc-900 text-center mb-6">
            {'Добавить платеж'}
          </h2>
          <div className="flex gap-4 mb-6">
            <div className="flex-1">
              <p className={'font-medium text-zinc-600 text-sm mb-1'}>
                Оплата: {numberWithSpaces(sum)}
              </p>
              <input
                type="number"
                placeholder="Цена"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                }
                value={sum}
                onChange={(v) => setSum(v.target.value)}
              />
            </div>
            <div className="flex-1">
              <p className={'font-medium text-zinc-600 text-sm mb-1'}>
                Курс: {dollar}
              </p>

              <input
                type="date"
                placeholder="Цена"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                }
                value={dateC}
                onChange={(v) => getUSD(v.target.value)}
              />
            </div>
          </div>

          <h3 className="text-base font-medium text-zinc-900 text-center mb-4">
            Способ оплаты
          </h3>
          <div className="grid md:grid-cols-3 gap-4 mb-6">
            {typeOfPayment.map((v, i) => {
              return (
                <>
                  <button
                    key={i}
                    onClick={() => changeActive(v.value, i)}
                    className={v.isActive ? 'ac' : 'inac'}
                  >
                    <div className="flex justify-center">{v.icon}</div>
                    <p className="btnTitle"> {v.title}</p>
                  </button>
                </>
              )
            })}
            {typeOfPayment[1].isActive && (
              <div className="relative">
                <input
                  type="text"
                  list="productName"
                  placeholder="Карта"
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                  }
                  value={account}
                  onChange={(v) => setAccount(v.target.value)}
                />
                <datalist id="productName">
                  {accountsData.map((v, i) => (
                    <option
                      key={i}
                      value={`${get(v, 'Name', '')} , ${get(v, 'Code', '')}`}
                    />
                  ))}
                </datalist>
              </div>
            )}
          </div>

          <div>
            <h3 className="text-base font-medium text-zinc-900 text-center mb-4">
              Valyuta
            </h3>
            <div className="grid grid-cols-2 gap-4 mb-8">
              {valuteDate.map((v, i) => {
                return (
                  <button
                    key={i}
                    onClick={() => changeActive2(v.value, i)}
                    className={v.isActive ? 'ac' : 'inac'}
                  >
                    {v.icon}
                    <p className="btnTitle"> {v.title}</p>
                  </button>
                )
              })}
            </div>
          </div>

          <div className="centerCard">
            <button
              className="btnN"
              onClick={() => {
                onClose()
                setDateC(moment().format('YYYY-MM-DD'))
              }}
            >
              {'Нет'}
            </button>
            <button
              className="btnY"
              onClick={() => sending()}
              disabled={
                Number(sum) > 0 && typeOfPayment.length && valuta.length > 0
                  ? false
                  : true
              }
            >
              {'Да'}
            </button>
          </div>
        </div>
      </PaymentModalStyle>
    </Modal>
  )
}

export default memo(PaymentModal)
