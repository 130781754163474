import React, { useEffect, useRef, useState } from "react";
import CreatePurchases from "./CreatePurchases";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import api, { customFuncsApi } from "../../api";
import { debounce, get } from "lodash";
import _ from "lodash";
import Layout from "../../components/Layout";
import { CreateUser, ErrorModal } from "../../components/Modal";
import ClipLoader from "react-spinners/ClipLoader";
import { isAxiosError } from "axios";
import moment from "moment";

const CreatePurchasesPage = () => {
  const succesCreateUserRef = useRef();
  const createUserRef = useRef();
  const navigation = useNavigate();
  const errorRef = useRef();
  const inputRef = useRef(null);
  const [businessPartnersData, setBusinessPartnersData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [whichInput, setWhichInput] = useState(null);
  const [loadingBusinessPartners, setLoadingBusinessPartners] = useState(false);
  const [form, setForm] = useState({
    CardCode: "",
    DocDate: moment().format("YYYY-MM-DD") + "T00:00:00",
    CardName: "",
    DocCurrency: "USD",
    DocumentLines: [],
  });
  const [createUserLoading, setCreateUserLoading] = useState(false);
  const initItem = {
    ItemCode: "",
    Quantity: 0,
    UnitPrice: 0,
    SerialNumbers: "",
    U_IMEI_PURCH: "IMEI",
  };

  const [items, setItems] = useState([
    {
      ...initItem,
    },
  ]);

  const [itemsName, setItemsName] = useState({});

  const [btnLoading, setBtnLoading] = useState(false);

  const getBusinessPartners = async (name = "") => {
    if (name.length <= 0) {
      setBusinessPartnersData([]);
      return;
    }
    setLoadingBusinessPartners(true);
    const data = await api(
      `BusinessPartners?$select=CardCode,CardName&$filter=CardType eq 'cSupplier' and contains(CardName,'${name}')`,
    ).then((res) => {
      return JSON.parse(res.data).value;
    });

    const selectElementData = data.map((item) => {
      return {
        value: item.CardCode,
        label: item.CardName,
      };
    });

    setBusinessPartnersData(selectElementData);
    setLoadingBusinessPartners(false);
  };

  const createUserF = (consumer, passport, male, address, phone, cardCode) => {
    setCreateUserLoading(true);
    api
      .post(`BusinessPartners`, {
        CardCode: cardCode,
        CardName: consumer,
        CardType: "cSupplier",
        Phone1: phone,
        U_Gender: male,
        U_PS: passport,
        Currency: "##",
        BPAddresses: [
          {
            AddressName: address,
            Street: address,
          },
        ],
      })
      .then(() => {
        createUserRef.current?.close();
        succesCreateUserRef.current?.open("Пользователь успешно создан");
      })
      .catch((err) => {
        errorRef.current?.open(
          get(
            JSON.parse(err.response.data),
            "error.message",
            "Не удалось получить данные, попробуйте еще раз.",
          ),
        );
      })
      .finally(() => {
        setCreateUserLoading(false);
      });
  };

  const purchaseInvoicesPost = async () => {
    const uniqueItems = [];
    items.forEach((item) => {
      // uniqueItems.forEach((v) => {
      //   if (v.ItemCode === item.ItemCode) {
      //     v.Quantity += 1
      //     v.SerialNumbers.push({ InternalSerialNumber: item.SerialNumbers })
      //     return
      //   }
      // })
      // if (
      //   !uniqueItems.find((v) => v.ItemCode === item.ItemCode) &&
      //   item.ItemCode
      // ) {
      {
        uniqueItems.push({
          ItemCode: item.ItemCode,
          Quantity: 1,
          SerialNumbers: [{ InternalSerialNumber: item.SerialNumbers }],
          UnitPrice: item.UnitPrice,
          U_IMEI_PURCH: item.U_IMEI_PURCH,
        });
      }
    });
    const formatedDate = {
      ...form,
      DocumentLines: uniqueItems,
    };

    setBtnLoading(true);

    try {
      await api.post(`PurchaseInvoices`, formatedDate).then((res) => {
        return JSON.parse(res.data).value;
      });
      navigation("/purchases");
    } catch (e) {
      if (isAxiosError(e)) {
        errorRef.current?.open(
          get(JSON.parse(e.response.data), "error.message", ""),
        );
      }
    } finally {
      setBtnLoading(false);
    }
  };

  const getItems = async (name = "", i) => {
    if (name.length <= 0) {
      setItemsData([]);
      return;
    }
    setWhichInput(i);
    const data = await api(
      `Items?$select=ItemName,ItemCode,U_Condition&$filter=Frozen eq 'tNO' and contains(ItemName,'${name}')`,
    ).then((res) => {
      return JSON.parse(res.data).value;
    });

    setItemsData(data);
  };

  useEffect(() => {
    getBusinessPartners();
  }, []);

  const handleBusinessPartner = _.debounce((e) => {
    getBusinessPartners(e.target.value);
  }, 1000);

  const handleItem = _.debounce((e, i) => {
    getItems(e.target.value, i);
  }, 1000);

  useEffect(() => {
    setForm((prev) => ({ ...prev, DocumentLines: selectedItem }));
  }, [selectedItem]);

  const handleRemoveItem = (code) => {
    setSelectedItem((prevState) =>
      prevState.filter((item) => item.ItemCode !== code),
    );
    setItemsData((prev) => [
      ...prev,
      selectedItem.find((item) => item.ItemCode === code),
    ]);
  };

  const handlePostBtnClick = () => {
    purchaseInvoicesPost();
  };

  const handleStartDateChange = (event) => {
    setForm((prev) => ({
      ...prev,
      DocDate: moment(event.target.value).format("YYYY-MM-DD") + "T00:00:00",
    }));
  };

  const handleCurrencyChange = (event) => {
    setForm((prev) => ({ ...prev, DocCurrency: event.target.value }));
  };

  const handleBusinessPartnerClick = (event) => {
    if (inputRef.current) {
      inputRef.current.value = event.target.innerText;
    }
    setForm((prev) => ({
      ...prev,
      CardName: event.target.innerText,
      CardCode: businessPartnersData.find(
        (item) => item.CardName === event.target.value,
      ).value,
    }));
    setBusinessPartnersData([]);
  };

  const handleAddItem = () => {
    setItems((prev) => [...prev, { ...initItem }]);
  };

  const handleClickItem = (code, i) => {
    setItems((prev) => [
      ...prev.map((item, idx) => {
        if (idx === i) {
          return {
            ...item,
            ItemCode: code,
            UnitPrice: items.find((v) => v.ItemCode === code)?.UnitPrice,
          };
        }
        return item;
      }),
    ]);
    setItemsName((prev) => ({
      ...prev,
      [i]: itemsData.find((v) => v.ItemCode === code).ItemName,
    }));
    setItemsData([]);
  };

  const handleConditionChange = (event, i) => {
    setItems((prev) => [
      ...prev.map((item, idx) => {
        if (idx === i) {
          return {
            ...item,
            Condition: event.target.value,
          };
        }
        return item;
      }),
    ]);
  };

  const handleImeiPurch = (value, i) => {
    setItems((prev) => [
      ...prev.map((item, idx) => {
        if (idx === i) {
          return {
            ...item,
            U_IMEI_PURCH: value,
          };
        }
        return item;
      }),
    ]);
  };
  const handleSerialNumberChange = (event, i) => {
    setItems((prev) => [
      ...prev.map((item, idx) => {
        if (idx === i) {
          return {
            ...item,
            SerialNumbers: event.target.value,
          };
        }
        return item;
      }),
    ]);
  };

  const handlePriceChange = (event, i) => {
    setItems((prev) => [
      ...prev.map((item, idx) => {
        if (idx === i) {
          return {
            ...item,
            UnitPrice: event.target.value,
          };
        }
        return item;
      }),
    ]);
  };

  return (
    <Layout>
      <CreatePurchases>
        <div className="container">
          <div className="flex gap-4 items-start">
            <div className="flex gap-2 items-start">
              <div className="searchable-select">
                <div className="relative">
                  <input
                    className={
                      'border-[1px] border-[#DFE2E9"] p-1 rounded-md pl-4'
                    }
                    ref={inputRef}
                    type="text"
                    onChange={handleBusinessPartner}
                    placeholder="Бизнес партнер"
                  />
                  {loadingBusinessPartners && (
                    <ClipLoader size={10} className="absolute right-2 top-3" />
                  )}
                </div>
                {!!businessPartnersData.length && (
                  <div className="searchable-select-list">
                    {businessPartnersData.map((item) => {
                      return (
                        <div
                          key={item.value}
                          className="searchable-select-item"
                          onClick={handleBusinessPartnerClick}
                        >
                          {item.label}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <button
                className="bg-[#0A4D68] text-white px-4 rounded-md py-1"
                onClick={() => createUserRef.current?.open()}
              >
                +
              </button>
            </div>
            <input
              className={'border-[1px] border-[#DFE2E9"] p-1 rounded-md'}
              type="date"
              value={moment(form.DocDate).format("YYYY-MM-DD")}
              onChange={handleStartDateChange}
            />
            <div className="flex flex-col">
              <select
                className={
                  'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
                }
                onChange={handleCurrencyChange}
              >
                <option>USD</option>
                <option>UZS</option>
              </select>
            </div>
          </div>
          <div className="overflow-y-auto my-8">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 "></thead>
              <tbody>
                {items.map((v, i) => {
                  return (
                    <tr className="bg-white  hover:bg-gray-50 " key={i}>
                      <td className="px-6 py-4 border">
                        <div className="searchable-select">
                          <div className={"searchableHead"}>
                            <div>
                              <input
                                className={
                                  'border-[1px] border-[#DFE2E9"] p-1 rounded-md'
                                }
                                type="text"
                                onChange={(event) => {
                                  handleItem(event, i);
                                  setItemsName((prev) => ({
                                    ...prev,
                                    [i]: event.target.value,
                                  }));
                                }}
                                placeholder="Наименование товара"
                                value={itemsName[i]}
                              />
                              {!!itemsData.length && i === whichInput && (
                                <div className="searchable-select-list">
                                  {itemsData.map((item) => {
                                    return (
                                      <div
                                        onClick={() =>
                                          handleClickItem(item.ItemCode, i)
                                        }
                                        key={item.ItemCode}
                                        className="searchable-select-item"
                                      >
                                        {item.ItemName}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 border">
                        <input
                          disabled={true}
                          className={
                            'border-[1px] border-[#DFE2E9"] p-1 rounded-md cursor-not-allowed'
                          }
                          value={
                            itemsData.find((v) => v.ItemCode === v.ItemCode)
                              ?.U_Condition
                          }
                        ></input>
                      </td>
                      <td className="px-6 border py-4">
                        <select
                          className={
                            'border-[1px] border-[#DFE2E9"] p-1 rounded-md'
                          }
                          onSelect={(event) => handleImeiPurch(event, i)}
                        >
                          <option value="IMEI">С имеи</option>
                          <option value="bez">Без</option>
                        </select>
                      </td>
                      <td className="px-6 border py-4">
                        <input
                          placeholder="ИМЕИ"
                          className={
                            'border-[1px] border-[#DFE2E9"] p-1 rounded-md'
                          }
                          onChange={(event) => {
                            handleSerialNumberChange(event, i);
                          }}
                        />
                      </td>
                      <td className="px-6 border py-4">
                        <input
                          className={
                            'border-[1px] border-[#DFE2E9"] p-1 rounded-md'
                          }
                          type="number"
                          placeholder="Цена"
                          onChange={(event) => {
                            handlePriceChange(event, i);
                          }}
                          value={v.UnitPrice}
                        />
                      </td>
                      {i === items.length - 1 ? (
                        <button
                          onClick={handleAddItem}
                          className="p-4 bg-[#0A4D68] text-white rounded-md ml-2"
                        >
                          +
                        </button>
                      ) : null}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              paddingRight: "50px",
              paddingTop: "30px",
            }}
          >
            <Button isLoading={btnLoading} onClick={handlePostBtnClick}>
              Создать
            </Button>
          </div>
        </div>
      </CreatePurchases>
      <>
        <CreateUser
          isLoading={createUserLoading}
          getRef={(r) => (createUserRef.current = r)}
          onConfirm={(consumer, passport, male, address, phone, cardCode) =>
            createUserF(consumer, passport, male, address, phone, cardCode)
          }
        />
        <ErrorModal
          getRef={(r) => {
            errorRef.current = r;
          }}
        />
      </>
    </Layout>
  );
};

export default CreatePurchasesPage;
