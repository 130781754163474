import React, { useEffect, useRef, useState } from 'react'
import { ErrorModal } from '../../components/Modal'
import { useLocation, useParams } from 'react-router-dom'
import Button from '../../components/Button'
import Layout from '../../components/Layout'
import DownloadStyle from './DownloadStyle'
import writtenNumber from 'written-number'
import DollarToWord from './DollarToWord'
import html2PDF from 'jspdf-html2canvas'
import { get } from 'lodash'
import api from '../../api'
import moment from 'moment'

const Download = () => {
  const location = useLocation()
  const errorRef = useRef()
  const dataLocation = get(location, 'state.data', {})
  const [userData, setuserData] = useState({})
  const [paymentDates, setPaymentDates] = useState([])
  const [item, setItem] = useState()
  const [invoice, setInvoice] = useState()

  useEffect(() => {
    getUser()
    getUSD()
    getInformationOfItem()
    getInfoOFDevice()
  }, [])

  const getInformationOfItem = () => {
    // setIsLoading(true)
    api
      .get(
        `SQLQueries('getInstallments')/List?docEntry='${location.state.data.DocEntry}'`,
        {
          headers: {
            Prefer: 'odata.maxpagesize=50',
          },
        },
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), 'value', [])
        setPaymentDates(resData)
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), 'error.message', ''),
        )
      })
    // setIsLoading(false)
  }

  const getUser = () => {
    api
      .get(
        `BusinessPartners('${get(
          dataLocation,
          'CardCode',
          0,
        )}')?$select=CardCode,CardName,Address,Phone1,Phone2,U_Gender,U_PS,BPAddresses,Fax`,
      )
      .then((res) => {
        const resData = JSON.parse(res.data)
        setuserData(resData)
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), 'error.message', ''),
        )
      })
  }

  const getUSD = () => {
    // api
    //   .get(
    //     `SQLQueries('getUSDRate')/List?date='${get(
    //       dataLocation,
    //       'DocDate',
    //       '',
    //     )}T00:00:00Z'`,
    //   )
    //   .then((res) => {
    //     const resData = get(JSON.parse(res.data), 'value', [])
    //     setDollar(get(resData, '[0].Rate', 0))
    //   })
    //   .catch((err) => {
    //     errorRef.current?.open(
    //       get(JSON.parse(err.response.data), 'error.message', ''),
    //     )
    //   })
  }

  const download = () => {
    let element = document.getElementsByClassName('contract')

    html2PDF(element, {
      margin: 10,
      filename: 'myfile.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, logging: true, dpi: 150, letterRendering: true },
      jsPDF: {
        unit: 'mm',
        format: 'a4',
        orientation: 'portrait',
        fontSize: 20,
      },
    })
  }

  const getInfoOFDevice = () => {
    api
      .get(`Invoices(${location.state.data.DocEntry})`)
      .then((res) => {
        const resData = get(JSON.parse(res.data), 'DocumentLines[0]', [])
        setInvoice(JSON.parse(res.data))
        console.log(
          JSON.parse(res.data).DocumentLines[0].SerialNumbers[0]
            .InternalSerialNumber,
        )
        getItems(get(resData, 'ItemCode', ''))
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), 'error.message', ''),
        )
      })
  }

  const getItems = (code) => {
    api
      .get(`Items('${code}')`)
      .then((res) => {
        const resDate = JSON.parse(res.data)
        setItem(resDate)
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), 'error.message', ''),
        )
      })
  }

  return (
    <Layout>
      <DownloadStyle>
        <div className="flex justify-end">
          <Button
            onClick={download}
            btnStyle={{ marginBottom: 10, width: 200 }}
          >
            Загрузить договор(Ру)
          </Button>
        </div>
        <div className="contract shadow-md">
          <h2 className="text-center">
            <b>Shartnoma</b>
          </h2>
          <div className="contract-content">
            <p>Men {userData.CardName}</p>
            <p>
              Djalilov Abdurasuldan Telefon turi:{' '}
              {item ? item?.ItemName : '_____________________________'}
            </p>
            <p>
              Rangi: {item?.U_Color ? item?.U_Color : '_____________'} , IMEI:{' '}
              {invoice?.DocumentLines[0]?.SerialNumbers[0]?.InternalSerialNumber
                ? invoice.DocumentLines[0].SerialNumbers[0].InternalSerialNumber
                : '_______________'}
              , %_____, GB_____, rusumli smartfon,
            </p>
            <p>
              Boshlang’ich{' '}
              {paymentDates ? paymentDates[0]?.InsTotal + '$' : '________'}{' '}
              to’lab {paymentDates ? paymentDates.length - 1 : '________'} oy{' '}
              {paymentDates ? paymentDates[1]?.InsTotal + '$' : '________'} dan
              (yoki to’lov summasini Tijorat Bankining sotuv kursi asosida)
              bo’lib to’lashga xarid qildim.
            </p>
            <p>
              Har oyning{' '}
              {paymentDates
                ? moment(paymentDates[0]?.DueDate).format('DD')
                : '________'}{' '}
              sanasidan to’lovni amalga oshiraman.
            </p>
            <p>
              To’lov sanalari:{' '}
              {paymentDates.length > 1
                ? paymentDates.map((item, idx) => {
                    if (idx > 0)
                      return moment(item.DueDate).format('DD-MM-YYYY') + ', '
                  })
                : '____________________________________________________________________'}
            </p>
            <p>
              Telefon raqam:{' '}
              {userData?.Phone1 || userData?.Phone2
                ? userData.Phone1 + ', ' + userData.Phone2
                : '+998_________________'}
            </p>
            <p>
              Yashash manzili:{' '}
              {userData?.BPAddresses
                ? userData.BPAddresses[0]?.AddressName
                : null}
            </p>
            <p>
              Ish joyi:{' '}
              {userData.Fax
                ? userData.Fax
                : '__________________________________________________________________________'}
            </p>
            <p className="text-center py-3">
              <b>Tilxat</b>
            </p>
            <p>
              Men{' '}
              {userData?.CardName
                ? userData?.CardName
                : '__________________________________'}
            </p>
            <p>
              Agar to’lovni o’z vaqtida amalga oshira olmasam telefonni Djalilov
              A. ga qaytarishga va boshlang’ich to’lovini Djalilov A.da
              qoldirishga roziman. Shuningdek, to’lovni belgilangan sanada
              amalga oshirmasam, telefon 5 kundan so’ng “blok” qilinishiga va
              ichidagi ma’lumotlarim o’chib ketishidan ogohlantirildim,
              ma’lumotlarim o’chib ketib, telefon “blok” qilinganidan keyin uni
              yoqish xizmati 30$ ligidan ogohlantirildim. Ushbu to’lov dan
              undirilishiga roziman. Telefonimdagi ma’lumotlarim o’chib ketsa
              Djalilov A.ga e’tirozim bo’lmaydi.
              _____________________________________________________________ agar
              to’lov summasi muddatidan avval to’lansa, kelishilgan to’lov
              summasi o’zgarmaydi. To’lov summasi to’lov qilinadigan kundagi
              Tijorat Bankining sotuv kursi asosida qabul qilinishidan
              ogohlantirildim. To’lov to’liq amalga oshirilmaguncha telefonni
              sotish va nasiyaga berish ta’qiqlanadi. Agar shunday holat
              aniqlansa, telefonni qaytarishga va boshlang’ich to’lovni Djalilov
              A.da qoldirishga roziman. Agar telefon yo’qotilsa yoki biror
              kor-hol sodir bo’lsa, to’lovni to’liq summasini oxirigacha
              to’lanishini kafolatlayman. Agar telefon boshqa birovga taqdim
              etilsa, ogohlantirib qo’yish shart! Agar ogohlantirishsiz telefon
              boshqa insonga taqdim qilinsa, telefon qaytarish majburiyatini
              zimmamga olaman, boshlang’ich to’lov va barcha oylik to’lovlar
              Djalilov A.da qolishiga roziman. Quyidagi qoldirilgan telefon
              raqamlar doim yoniq holatda bo’lishida va sababsiz aloqaga
              chiqilmagan taqdirda qonuniy choralar ko’rilishi va boshlang’ich
              to’lov Djalilov A.da qolgan holda telefon qaytarib olinishi haqida
              ogolantirildim.
            </p>
            <p className="text-end py-4">
              <b>Imzo:</b> __________
            </p>
            <p className="text-center">
              <b>Kafolat xati</b>
            </p>
            <p>
              Djalilov A. bilan tuzilgan tilxat-shartnomadagi barcha
              majburiyatlarni to'liq bajarilishini kafolatlayman. Kafolat
              xatinmni o'z qo'lim bilan yozdim.
            </p>
            <p className="py-4">
              Sana:{' '}
              {invoice?.DocDate
                ? moment(invoice?.DocDate).format('DD-MM-YYYY')
                : '_____________'}
            </p>
            <p>
              Icloud: {invoice?.U_Icloud ? invoice.U_Icloud : '__________'}{' '}
              Yozdirdi: _____________________________________ Imzo: _________
            </p>
          </div>
        </div>
      </DownloadStyle>
      <ErrorModal
        getRef={(r) => {
          errorRef.current = r
        }}
      />
    </Layout>
  )
}

export default Download
