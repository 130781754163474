import React, { useEffect, useRef, useState } from 'react'
import DDSReport from './DDSReport'
import Button from '../../../components/Button'
import { useNavigate } from 'react-router-dom'
import { customFuncsApi } from '../../../api'
import { get } from 'lodash'
import Layout from '../../../components/Layout'
import { ErrorModal } from '../../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'

const DDSReportPage = () => {
  const navigation = useNavigate()
  const errorRef = useRef()

  const [isLoading, setIsLoading] = useState(false)
  const [clientName, setClientName] = useState('')
  const [clientPhone, setClientPhone] = useState('')
  const [clientCode, setClientCode] = useState('')
  const [product, setProduct] = useState('')
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split('T')[0],
  )
  const [data, setData] = useState({
    data: [],
    currentPage: 0, //20
    nextPage: 0, //30
    oldPage: 0,
  })
  const search = (currentPage = 0, oldDataPage = 0) => {
    setIsLoading(true)
    customFuncsApi
      .get(
        `/getCashAccountsStats?date=${encodeURIComponent(startDate + 'T00:00:00.000Z')}`,
        {
          headers: {
            Prefer: 'odata.maxpagesize=10',
          },
        },
      )
      .then((res) => {
        const resData = res.data.value
        const nextPage = res.data['@odata.nextLink'].split('skip=')[1]

        if (oldDataPage === 0) {
          setData({
            data: [...resData],
            currentPage: currentPage === 0 ? 0 : data.nextPage,
            nextPage: nextPage === 0 ? data.currentPage : nextPage,
            oldPage: nextPage === 0 ? data.currentPage : nextPage - 20,
          })
        } else {
          setData({
            data: [...resData],
            currentPage: data.nextPage - 20,
            nextPage: nextPage,
            oldPage: nextPage < data.currentPage ? nextPage : nextPage - 20,
          })
        }
        setIsLoading(false)
      })
      .catch((err) => {
        if (isAxiosError(err))
          errorRef.current?.open(
            get(JSON.parse(err.response.data), 'error.message', ''),
          )
        setIsLoading(false)
      })
  }

  useEffect(() => {
    search(0, 0)
  }, [startDate])

  const viewItem = (v) => {
    navigation('/viewItem', { state: v })
  }

  const newDatas = () => {
    if (data.nextPage > data.currentPage) {
      search(data.nextPage, 0)
    } else {
      alert('boshqa malumot yoq')
    }
  }

  const oldData = () => {
    if (data.oldPage < 0) {
      alert('boshqa malumot yoq')
    } else {
      search(data.oldPage, 1)
    }
  }

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value)
  }

  return (
    <Layout>
      <DDSReport>
        <div className="container">
          <div className="mt-4">
            <p className="productTitle">Продукты</p>
          </div>
          <div className="searchCard mt-8">
            <div>
              <p className={'font-medium text-zinc-600 text-sm mb-1'}>
                Дата начала
              </p>
              <input
                className={
                  'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full'
                }
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
          </div>

          {isLoading ? (
            <ClipLoader
              loading={true}
              size={75}
              aria-label="Loading Spinner"
              className={'loader'}
              data-testid="loader"
            />
          ) : (
            <>
              <div className="overflow-y-auto my-8">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                      <th scope="col" className="px-6 py-4">
                        Код клиента
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Имя Клиента
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Товар
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Деньги
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Дата продажи
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.data.map((v, i) => {
                      return (
                        <tr
                          key={i}
                          onClick={() => viewItem(v)}
                          className="bg-white border-b  hover:bg-gray-50 "
                        >
                          <td className="px-6 py-4 font-medium text-gray-900 ">
                            {get(v, 'Invoices.CardCode', 'Код покупателя')}
                          </td>
                          <td className="px-6 py-4 font-medium text-gray-900 ">
                            {get(v, 'Invoices.CardName', 'Имя покупателя')}
                          </td>
                          <td className="px-6 py-4 font-medium text-gray-900 ">
                            {get(
                              v,
                              'Invoices/DocumentLines.ItemDescription',
                              '',
                            )}
                          </td>
                          <td className="px-6 py-4 font-medium text-gray-900 ">
                            {' '}
                            {get(v, 'Invoices/DocumentLines.LineTotal', '')} USD
                          </td>
                          <td className="px-6 py-4 font-medium text-gray-900 ">
                            {get(v, 'Invoices.DocDate', '')}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>

              <div className="topCard">
                <div className="flex">
                  <Button
                    className={'btn'}
                    onClick={oldData}
                    btnStyle={{ marginRight: 10 }}
                  >
                    {'<'}
                  </Button>
                  <Button className={'btn'} onClick={newDatas}>
                    {'>'}
                  </Button>
                </div>
                {/*<Button*/}
                {/*  className={'btn'}*/}
                {/*  onClick={() => navigation('/createProducts')}*/}
                {/*>*/}
                {/*  {'+'}*/}
                {/*</Button>*/}
              </div>
            </>
          )}
        </div>
      </DDSReport>
      <>
        <ErrorModal
          getRef={(r) => {
            errorRef.current = r
          }}
        />
      </>
    </Layout>
  )
}

export default DDSReportPage
