import Layout from "../../components/Layout";
import React, { useEffect, useRef, useState } from "react";
import { customFuncsApi } from "../../api";
import ClipLoader from "react-spinners/ClipLoader";
import { get } from "lodash";
import Button from "../../components/Button";
import { ErrorModal, SuccessModal, WarningModal } from "../../components/Modal";
import { roundCurrency } from "../../utils";

const SMSPage = () => {
  const errorRef = useRef();
  const successModalRef = useRef();
  const warningRef = useRef();

  const [debtorsData, setDebtorsData] = useState([]);
  const [docEntries, setDocEntries] = useState([]);
  const [getLoading, setGetLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [debtors, setDebtors] = useState("");
  const [comingDebtors, setComingDebtors] = useState("");
  const [form, setForm] = useState({
    cardNumber: "",
    cardHolder: "",
    currencyRate: "",
  });

  useEffect(() => {
    GetDebtors();
  }, []);

  const GetDebtors = () => {
    //api ga filter qo'shvorasiz
    // tahminan servicelayer uchun=> ?$filter=contains(CardName, '${debtors}') and contains(CardName2, '${comingDebtors}')
    setGetLoading(true);
    customFuncsApi
      .get(
        `/XsSql/getDebtsForSmsSending`,

        {
          headers: {
            Prefer: "odata.maxpagesize=9000",
          },
        },
      )
      .then((res) => {
        setDebtorsData(res.data.value);
        const allEntries = [];
        res.data.value.forEach((v) => {
          if (v.DocEntry) {
            allEntries.push(v.DocEntry);
          }
        });
        setDocEntries(allEntries);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      })
      .finally(() => {
        setGetLoading(false);
      });
  };

  const SendSMS = () => {
    setSendLoading(true);
    customFuncsApi
      .post("/sendSmssToDebtors", {
        ...form,
        docEntries,
      })
      .then((res) => {
        successModalRef.current?.open(JSON.stringify(res.data));
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      })
      .finally(() => {
        setSendLoading(false);
      });
  };

  const handleCheck = (docEntry) => {
    if (docEntries.length === 0) {
      alert("DocEntries пуст!");
      return;
    }
    const newDocEntries = docEntries;
    if (newDocEntries.includes(docEntry))
      newDocEntries.splice(newDocEntries.indexOf(docEntry), 1);
    else newDocEntries.push(docEntry);

    setDocEntries([...newDocEntries]);
  };

  const handleChangeAll = () => {
    if (docEntries.length === 0) {
      const allDocEntries = [];
      debtorsData.forEach((v) => {
        if (v.DocEntry) {
          allDocEntries.push(v.DocEntry);
        }
      });
      setDocEntries(allDocEntries);
    } else {
      setDocEntries([]);
    }
  };

  return (
    <Layout>
      <div className="p-16">
        <div className="shadow-md p-4">
          <div className="flex gap-3 mb-8">
            <input
              placeholder="Qarzdorlar"
              value={debtors}
              onChange={(e) => setDebtors(e.target.value)}
              className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
            />
            <input
              placeholder="Qarz sanasi kelayotganlar"
              value={comingDebtors}
              onChange={(e) => setComingDebtors(e.target.value)}
              className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
            />

            <Button isLoading={getLoading} onClick={GetDebtors}>
              Поиск
            </Button>
          </div>
          <div className="flex gap-3 mb-8">
            <input
              placeholder="Номер карты"
              className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
              onChange={(e) =>
                setForm((prevState) => ({
                  ...prevState,
                  cardNumber: e.target.value,
                }))
              }
            />
            <input
              placeholder="Название карты"
              className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
              onChange={(e) =>
                setForm((prevState) => ({
                  ...prevState,
                  cardHolder: e.target.value,
                }))
              }
            />
            <input
              placeholder="Курс доллара"
              className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
              onChange={(e) =>
                setForm((prevState) => ({
                  ...prevState,
                  currencyRate: e.target.value,
                }))
              }
            />
            <Button isLoading={sendLoading} onClick={SendSMS}>
              Отправить
            </Button>
          </div>
          <div className="min-h-[50vh] flex items-center justify-center w-full">
            {getLoading ? (
              <ClipLoader
                loading={true}
                size={35}
                aria-label="Loading Spinner"
                className={"loader"}
                data-testid="loader"
              />
            ) : (
              <div className="overflow-y-auto w-full">
                <table className="w-full text-sm text-left  text-gray-700 ">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                      <th scope="col" className="px-6 py-4">
                        <input
                          onChange={handleChangeAll}
                          checked={docEntries.length === debtorsData.length}
                          type={"checkbox"}
                        />
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Код клиента
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Имя Клиента
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Товар
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Номер телефона
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Сумма
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Сумма задолженности
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {debtorsData.map((v, i) => {
                      return (
                        <tr
                          key={v.DocEntry}
                          className="bg-white border-b  hover:bg-gray-50"
                        >
                          <td className="px-6 py-4 font-medium text-gray-900 ">
                            <input
                              className="cursor-pointer"
                              type="checkbox"
                              checked={docEntries.includes(
                                get(v, "DocEntry", ""),
                              )}
                              onChange={() => {
                                handleCheck(get(v, "DocEntry", ""));
                              }}
                            ></input>
                          </td>
                          <td className="px-6 py-4 font-medium text-gray-900 ">
                            {get(v, "CardCode", "-")}
                          </td>
                          <td className="px-6 py-4">
                            {get(v, "CardName", "-")}
                          </td>
                          <td className="px-6 py-4">
                            {get(v, "Dscription", "-")}
                          </td>
                          <td className="px-6 py-4">{get(v, "Phone1", "-")}</td>
                          <td className="px-6 py-4">
                            {roundCurrency(get(v, "DocTotal", "-"))}
                          </td>
                          <td className="px-6 py-4">
                            {roundCurrency(get(v, "DebtSum", "-"))}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <>
        <ErrorModal
          getRef={(r) => (errorRef.current = r)}
          onConfirm={() => errorRef.current?.close()}
        />
        <SuccessModal
          getRef={(r) => (successModalRef.current = r)}
          onConfirm={() => successModalRef.current?.close()}
        />
        <WarningModal
          getRef={(r) => (warningRef.current = r)}
          onConfirm={() => warningRef.current?.close()}
        />
      </>
    </Layout>
  );
};

export default SMSPage;
