import React, { useEffect, useRef, useState } from "react";
import { SuccessModal, PaymentModal, ErrorModal } from "../../components/Modal";
import ViewRecoveryItemStyle from "./ViewRecoveryItemStyle";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Layout from "../../components/Layout";
import { useSelector } from "react-redux";
import { get } from "lodash";
import api, { customFuncsApi } from "../../api";
import { AiOutlineSend } from "react-icons/ai";
import { isEmpty } from "lodash/lang";
import { roundCurrency } from "../../utils";
import moment from "moment";
import CloseIcon from "../../assets/icons/close";
import ClipLoader from "react-spinners/ClipLoader";
import numberWithSpaces from "../../helpers/numberWithSpaces";
import "moment-timezone";

const ViewRecoveryItem = () => {
  const { getMe } = useSelector((state) => state.main);

  const navigate = useNavigate();
  const location = useLocation();

  const successRef = useRef();
  const errorRef = useRef();
  const paymentRef = useRef();
  const commentRef = useRef(null);

  const executer = get(location, "state.executer", []);
  const DocEntry = get(location, "state.data.DocEntry", 0);
  const InstlmntID = get(location, "state.data.InstlmntID", 0);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [employeeID, setEmployeeID] = useState("");

  const [isCommitLoading, setIsCommitLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [isComment, setIsComment] = useState({
    loading: false,
    order: -1,
  });
  const [dollar, setDollar] = useState(1);
  // const [deviceInfoData, setDeviceInfoData] = useState([])
  const [isLoading2, setIsLoading2] = useState(false);
  const [sumPay, setSumPay] = useState(0);
  const [payingSum, setPayingSum] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const successModalRef = useRef();
  const [agreedDate, setAgreedDate] = useState();

  useEffect(() => {
    // Scroll to bottom when comments change
    if (commentRef.current) {
      commentRef.current.scrollTop = commentRef.current.scrollHeight;
    }
  }, [comments]);

  useEffect(() => {
    getAllData();
    getComments();
    getInformationOfItem();
  }, []);

  // const getInfoOFDevice = () => {
  //   setIsLoading2(true)
  //
  //   api
  //     .get(`Invoices(${DocEntry})`)
  //     .then((res) => {
  //       const resData = get(JSON.parse(res.data), 'DocumentLines[0]', [])
  //       setDeviceInfoData(resData)
  //       setIsLoading2(false)
  //     })
  //     .catch((err) => {
  //       errorRef.current?.open(
  //         get(JSON.parse(err.response.data), 'error.message', ''),
  //       )
  //     })
  // }

  const getInformationOfItem = () => {
    setIsLoading(true);
    api
      .get(`SQLQueries('getInstallments')/List?docEntry='${DocEntry}'`, {
        headers: {
          Prefer: "odata.maxpagesize=50",
        },
      })
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setTableData2(resData);
        setTableData(resData);
        setIsLoading(false);
        let a = JSON.parse(JSON.stringify(resData));
        let allSum = 0;
        let paySum = 0;
        const now = moment().format("YYYYMMDD");
        for (let i = 0; i < a.length; i++) {
          if (!moment(a[i].DueDate, "YYYYMMDD").isAfter(now, "day")) {
            allSum = allSum + a[i].PaidToDate;
            paySum = paySum + a[i].InsTotal;
          }
        }

        setSumPay(allSum);
        setPayingSum(paySum);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      });
  };

  const getAllData = async () => {
    await customFuncsApi
      .get(`installment?DocEntry=${DocEntry}&InstlmntID=${InstlmntID}`)
      .then((res) => {
        setData(get(res, "data", {}));
        setAgreedDate(
          moment(get(res, "data.U_PromisedDate", "")).format("YYYY-MM-DD"),
        );
        setEmployeeID(get(res, "data.U_Employee", 0));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          navigate("/login", { replace: true });
        }
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      });
  };

  const getComments = () => {
    setCommentsLoading(true);
    api
      .get(
        `U_COMMENTS?$filter=U_Invoice eq ${DocEntry} and U_InstlmntID eq ${InstlmntID}&$orderby=U_CreateTime asc, Code desc`,
      )
      .then((res) => {
        setComments(get(JSON.parse(res.data), "value", []));
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      })
      .finally(() => {
        setCommentsLoading(false);
      });
  };

  const deleteComments = (id, order) => {
    setIsComment((prevState) => ({ ...prevState, loading: true, order }));
    api
      .delete(`U_COMMENTS(${id})`)
      .then((res) => getComments())
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      })
      .finally((res) => {
        setIsComment((prevState) => ({
          ...prevState,
          loading: false,
          order: -1,
        }));
      });
  };

  const sendComment = () => {
    setComment("");
    const date = moment().tz("Etc/GMT-5").format("YYYY-MM-DDTHH:mm:ssZ");

    const body = {
      U_Employee: get(getMe, "EmployeeID", ""),
      U_CreateDate: date,
      U_Invoice: get(data, "DocEntry", ""),
      U_InstlmntID: InstlmntID,
      U_Text: comment,
      U_CreateTime: date,
    };
    api
      .post("U_COMMENTS", body)
      .then((res) => getComments())
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      });
  };

  // const createPayment = (sum, typeOfPayment, valuta, courseDollar) => {
  //   setIsLoading(true)
  //
  //   api
  //     .post(`IncomingPayments`, {
  //       CardCode: get(data, 'CardCode', 0),
  //       CashSum: Number(sum),
  //       CashAccount: getMe[typeOfPayment],
  //       BankChargeAmount: 0,
  //       DocCurrency: valuta,
  //       PaymentInvoices: [
  //         {
  //           DocEntry,
  //           InstallmentId: get(data, 'InstlmntID', 0),
  //           SumApplied:
  //             valuta === 'UZS'
  //               ? Number(sum) / Number(courseDollar)
  //               : Number(sum),
  //         },
  //       ],
  //     })
  //     .then(() => {
  //       successRef.current?.open('Платеж успешно создан')
  //       setIsLoading(false)
  //     })
  //     .catch((err) => {
  //       setIsLoading(false)
  //       errorRef.current?.open(
  //         get(JSON.parse(err.response.data), 'error.message', ''),
  //       )
  //     })
  // }

  const createPayment = (
    sum,
    typeOfPayment,
    valuta,
    courseDollar,
    cashAccount,
  ) => {
    setIsLoading(true);
    console.log(valuta);
    setDollar(Number(courseDollar));
    let kiritilganSum =
      valuta === "UZS" ? Number(sum) / Number(courseDollar) : Number(sum);
    let kiritilganSum2 =
      valuta === "UZS" ? Number(sum) / Number(courseDollar) : Number(sum);

    let filteredData = tableData.filter((v) => {
      return v.InsTotal !== v.PaidToDate;
    });

    let filteredData2 = tableData2.filter((v) => {
      return v.InsTotal !== v.PaidToDate;
    });

    let aa = [...filteredData];
    let goingBack = JSON.parse(JSON.stringify(filteredData));

    for (let i = 0; i < filteredData.length; i++) {
      let left = Number(filteredData[i].InsTotal);
      let right = Number(filteredData[i].PaidToDate);

      let left2 = Number(filteredData2[i].InsTotal);
      let right2 = Number(filteredData2[i].PaidToDate);

      if (kiritilganSum + right >= left) {
        aa[i].PaidToDate = left;
        goingBack[i].PaidToDate =
          Number(goingBack[i].InsTotal) - Number(goingBack[i].PaidToDate);
        kiritilganSum = kiritilganSum - (left - right);
        kiritilganSum2 = kiritilganSum2 + right2 - left2;
      } else if (kiritilganSum + right < left) {
        aa[i].PaidToDate = kiritilganSum + right;
        goingBack[i].PaidToDate = kiritilganSum2;
        kiritilganSum = 0;
        kiritilganSum2 = 0;
      }
    }

    let dataPFilter = goingBack.filter((v) => {
      return v.PaidToDate > 0;
    });

    let dataPFilterMain = dataPFilter.map((v) => {
      return {
        DocEntry: DocEntry, // Qaysi sotuvga to'lanyapti.
        InstallmentId: v.InstlmntID, // birinchi oyga
        SumApplied: v.PaidToDate, // birinchi oyga to'lo'v
      };
    });
    console.log(data);
    api
      .post(`IncomingPayments`, {
        CardCode: get(data, "CardCode", 0), // Kim to'layapti. BusinessPartner->CardCode
        CashSum: Number(sum),
        CashAccount: cashAccount
          ? cashAccount.split(" , ")[1]
          : getMe[typeOfPayment],
        PaymentInvoices: dataPFilterMain,
        BankChargeAmount: 0,
        DocRate: valuta === "UZS" ? courseDollar : undefined,
        DocCurrency: valuta,
      })
      .then(() => {
        setIsLoading(false);
        successModalRef.current?.open("Платеж успешно произведен!");
      })
      .catch((err) => {
        setIsLoading(false);
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      });
  };

  const cancel = () => {
    navigate("/recovery");
  };

  const allSaves = () => {
    setIsCommitLoading(true);
    api
      .patch(`Invoices(${get(data, "DocEntry", 0)})`, {
        DocumentInstallments: [
          {
            InstallmentId: get(data, "InstlmntID", 0),
            U_Comment: comment,
            U_Employee: employeeID > 0 ? employeeID : null,
            U_PromisedDate: agreedDate,
          },
        ],
      })
      .then(() => {
        successRef.current?.open("Заказ успешно сохранен");
        setIsCommitLoading(false);
      })
      .catch((err) => {
        setIsCommitLoading(false);
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      });
  };

  return (
    <Layout>
      <ViewRecoveryItemStyle>
        <div className="flex gap-2 flex-col md:flex-row flex-1">
          <div className="container shadow-lg  p-2 border-[1px] border-[#DFE2E8] flex gap-4 flex-col xl:flex-row">
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 w-full h-fit">
              <div>
                <p className={"font-medium text-zinc-600 text-sm mb-1"}>Код</p>
                <input
                  type="text"
                  className={
                    'border-[1px] border-[#DFE2E9"] text-green-700 p-2 rounded-md w-full'
                  }
                  value={get(data, "DocNum", "Kod")}
                  disabled={true}
                />
              </div>
              <div>
                <p className={"font-medium text-zinc-600 text-sm mb-1"}>ФИО</p>
                <input
                  type="text"
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 text-green-700 rounded-md w-full'
                  }
                  value={get(data, "CardName", "Покупатель")}
                  disabled={true}
                />
              </div>
              <div>
                <p className={"font-medium text-zinc-600 text-sm mb-1"}>
                  Продукт
                </p>
                <input
                  type="text"
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 text-green-700 rounded-md w-full'
                  }
                  value={get(data, "Dscription", "Товар")}
                  disabled={true}
                />
              </div>
              <div>
                <p className={"font-medium text-zinc-600 text-sm mb-1"}>Тел.</p>
                <input
                  type="text"
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 text-green-700 rounded-md w-full'
                  }
                  value={get(data, "Phone1", "Tel")}
                  disabled={true}
                />
              </div>
              {/*<div>*/}
              {/*  <p className={'font-medium text-zinc-600 text-sm mb-1'}>*/}
              {/*    Оплачено за этот месяц*/}
              {/*  </p>*/}
              {/*  <input*/}
              {/*    type="text"*/}
              {/*    className={*/}
              {/*      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'*/}
              {/*    }*/}
              {/*    value={roundCurrency(get(data, 'INV6.PaidToDate', '0'))}*/}
              {/*    disabled={true}*/}
              {/*  />*/}
              {/*</div>*/}
              <div>
                <p className={"font-medium text-zinc-600 text-sm mb-1"}>
                  Общий долг клиента
                </p>
                <input
                  type="text"
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 text-green-700 rounded-md w-full'
                  }
                  value={roundCurrency(
                    +get(data, "DocTotal", "0") - +get(data, "PaidToDate", "0"),
                  )}
                  disabled={true}
                />
              </div>
              {/*<div>*/}
              {/*  <p className={'font-medium text-zinc-600 text-sm mb-1'}>Цена</p>*/}
              {/*  <input*/}
              {/*    type="number"*/}
              {/*    placeholder="Текущая цена"*/}
              {/*    className={*/}
              {/*      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'*/}
              {/*    }*/}
              {/*    value={roundCurrency(*/}
              {/*      get(data, 'DocTotal', 'Текущая цена не найден'),*/}
              {/*    )}*/}
              {/*    disabled={true}*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*  <p className={'font-medium text-zinc-600 text-sm mb-1'}>*/}
              {/*    Заплатил за эту покупку*/}
              {/*  </p>*/}
              {/*  <input*/}
              {/*    type="number"*/}
              {/*    placeholder="Цена"*/}
              {/*    className={*/}
              {/*      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'*/}
              {/*    }*/}
              {/*    value={roundCurrency(*/}
              {/*      get(data, 'PaidToDate', 'Цена не найден'),*/}
              {/*    )}*/}
              {/*    disabled={true}*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*  <p className={'font-medium text-zinc-600 text-sm mb-1'}>*/}
              {/*    Должен заплатить в этом месяце*/}
              {/*  </p>*/}
              {/*  <input*/}
              {/*    type="number"*/}
              {/*    placeholder="Цена"*/}
              {/*    className={*/}
              {/*      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'*/}
              {/*    }*/}
              {/*    value={roundCurrency(get(data, 'InsTotal', 'Цена не найден'))}*/}
              {/*    disabled={true}*/}
              {/*  />*/}
              {/*</div>*/}
              <div>
                <p className={"font-medium text-zinc-600 text-sm mb-1"}>ИМEИ</p>
                <input
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 text-green-700 rounded-md w-full'
                  }
                  value={get(data, "IntrSerial", "IntrSerial не найден")}
                  disabled={true}
                />
              </div>{" "}
              <div>
                <p className={"font-medium text-zinc-600 text-sm mb-1"}>Срок</p>
                <input
                  type={"date"}
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 text-green-700 rounded-md w-full'
                  }
                  value={agreedDate}
                  onChange={(v) => setAgreedDate(v.target.value)}
                />
              </div>
              <div>
                {/*<p className={"comment"}>Комментарии</p>*/}
                <p className={"font-medium text-zinc-600 text-sm mb-1"}>
                  Комментарий
                </p>
                <div className={"commentSection"}>
                  <textarea
                    name="comment"
                    // id="comment"
                    className={
                      'border-[1px] border-[#DFE2E9"] p-2 text-green-700  rounded-md w-full'
                    }
                    // cols="30"
                    rows="1"
                    value={comment}
                    onChange={(v) => setComment(v.target.value)}
                  />
                  <button
                    className={"sendComment"}
                    onClick={() => sendComment()}
                    disabled={isEmpty(comment)}
                  >
                    <AiOutlineSend />
                  </button>
                </div>
              </div>
              {/*<div>*/}
              {/*  <p className={'font-medium text-zinc-600 text-sm mb-1'}>*/}
              {/*    Дата платежа*/}
              {/*  </p>*/}
              {/*  <input*/}
              {/*    type="string"*/}
              {/*    placeholder="Цена"*/}
              {/*    className={*/}
              {/*      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'*/}
              {/*    }*/}
              {/*    defaultValue={get(data, 'DueDate', 'Sana').slice(0, 10)}*/}
              {/*    disabled={true}*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*  <p className={'font-medium text-zinc-600 text-sm mb-1'}>*/}
              {/*    Количество месяцев*/}
              {/*  </p>*/}
              {/*  <input*/}
              {/*    type="number"*/}
              {/*    placeholder="Цена"*/}
              {/*    className={*/}
              {/*      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'*/}
              {/*    }*/}
              {/*    value={get(data, 'Installmnt', 1) - 1}*/}
              {/*    disabled={true}*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*  <p className={'font-medium text-zinc-600 text-sm mb-1'}>*/}
              {/*    Текущий месяц*/}
              {/*  </p>*/}
              {/*  <input*/}
              {/*    type="number"*/}
              {/*    placeholder="Цена"*/}
              {/*    className={*/}
              {/*      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'*/}
              {/*    }*/}
              {/*    value={roundCurrency(get(data, 'InstlmntID', 1)) - 1}*/}
              {/*    disabled={true}*/}
              {/*  />*/}
              {/*</div>*/}
              {get(getMe, "Department2.Name", "") !== "Undiruv" ? (
                <div>
                  <p className={"font-medium text-zinc-600 text-sm mb-1"}>
                    Сменить приемник
                  </p>
                  <select
                    name="executer"
                    id="executer"
                    className={
                      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                    }
                    onChange={(v) => {
                      setEmployeeID(v.target.value);
                    }}
                    defaultValue={"s"}
                  >
                    {/*<option*/}
                    {/*  value={''}*/}
                    {/*  selected={get(data, 'U_Employee', '') === 0}*/}
                    {/*>*/}
                    {/*  {''}*/}
                    {/*</option>*/}
                    {executer.map((v, i) => {
                      return (
                        <option
                          value={get(v, "EmployeesInfo.EmployeeID", 0)}
                          key={i}
                          selected={
                            v.EmployeesInfo.EmployeeID ===
                            get(data, "U_Employee", "")
                          }
                        >
                          {get(v, "EmployeesInfo.FirstName", "Name")}{" "}
                          {get(v, "EmployeesInfo.LastName", "Surename")}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : null}
              <div className="flex gap-3 flex-col lg:flex-row justify-end w-full lg:col-span-2 sm:col-span-2">
                <div className="flex gap-3">
                  <Button
                    btnStyle={{ backgroundColor: "red" }}
                    onClick={cancel}
                  >
                    Назад
                  </Button>
                </div>
                <Button
                  btnStyle={{ width: 250 }}
                  hoverBtnStyle={{ width: 250 }}
                  onClick={allSaves}
                  isLoading={isCommitLoading}
                >
                  Сохранять
                </Button>
              </div>
            </div>
            <div>
              <div className="flex flex-col sm:flex-row gap-2 sm:gap-6">
                <p>
                  <span className="font-bold text-zinc-900">Курс:</span>{" "}
                  {numberWithSpaces(dollar)} UZS
                </p>
                <p>
                  <span className="font-bold text-zinc-900">Долг:</span>{" "}
                  <span className="debt">
                    -{(+payingSum - +sumPay).toFixed(2)}
                  </span>{" "}
                  USD
                </p>
              </div>
              <div className="center py-5">
                {get(getMe, "Department2.Name", "") === "Boshqaruv" ? (
                  <Button
                    btnStyle={{ backgroundColor: "#243AB5" }}
                    onClick={() => paymentRef.current?.open()}
                    isLoading={isLoading || isLoading2}
                    hoverBtnStyle={{ backgroundColor: "#243AB5" }}
                  >
                    Оплатить
                  </Button>
                ) : null}
              </div>

              <div className="overflow-y-auto mb-8">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
                    <tr>
                      <th scope="col" className="px-6 py-4">
                        N
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Дата
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Оплата
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Оплачено
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((v, i) => {
                      let a = v.DueDate.split("");
                      a.splice(4, 0, "-");
                      a.splice(7, 0, "-");
                      a.join();
                      return (
                        <tr
                          className="bg-gray-300 border-b  hover:bg-gray-400"
                          key={i}
                        >
                          <td className="px-6 py-4 font-medium text-green-700 font-semibold ">
                            {i}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-green-700 font-semibold">{a}</td>
                          <td className="px-6 py-4 text-green-700 font-semibold">
                            {Number(v.InsTotal).toFixed(2)}
                          </td>
                          <td className="px-6 py-4 text-green-700 font-semibold">{v.PaidToDate}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="shadow-md w-full  md:min-w-[300px] md:max-w-[350px] border-[1px] border-[#DFE2E8]">
            <div className="p-4 shadow-md">
              Комментарии{" "}
              {commentsLoading.loading && (
                <ClipLoader
                  loading={true}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )}
            </div>
            <div ref={commentRef} className="p-4 h-[85vh] overflow-y-scroll">
              {comments.map((v, i) => {
                return (
                  <div className="mb-4">
                    <p className={"font-medium text-zinc-400 text-sm mb-1"}>
                      {moment(get(v, "U_CreateTime", "")).format(
                        "DD-MM-YYYY HH:mm",
                      )}
                      {isComment.loading && isComment.order === i && (
                        <ClipLoader
                          loading={true}
                          size={10}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )}
                    </p>
                    <div className="inputCard2">
                      <div className="p-3 pt-5 font-medium rounded-lg rounded-bl-none bg-[#29d2f5] text-white relative">
                        <button
                          className="absolute top-1 right-1 z-10 cursor-pointer"
                          onClick={() => deleteComments(get(v, "Code", ""), i)}
                        >
                          <CloseIcon className="text-white w-[20px] " />
                        </button>
                        {get(v, "U_Text", "")}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </ViewRecoveryItemStyle>
      <>
        <ErrorModal getRef={(r) => (errorRef.current = r)} />
        {/*<PaymentModal*/}
        {/*  getRef={(r) => {*/}
        {/*    paymentRef.current = r*/}
        {/*  }}*/}
        {/*  onConfirm={(sum, typeOfPayment, valuta, courseDollar) =>*/}
        {/*    createPayment(sum, typeOfPayment, valuta, courseDollar)*/}
        {/*  }*/}
        {/*/>*/}
        <PaymentModal
          getRef={(r) => (paymentRef.current = r)}
          onConfirm={(sum, typeOfPayment, valuta, courseDollar, cashAccount) =>
            createPayment(sum, typeOfPayment, valuta, courseDollar, cashAccount)
          }
          onClose={() => paymentRef.current?.close()}
        />
        <SuccessModal
          getRef={(r) => {
            successRef.current = r;
          }}
        />
        <SuccessModal
          getRef={(r) => (successModalRef.current = r)}
          onConfirm={() => successModalRef.current?.close()}
        />
      </>
    </Layout>
  );
};

export default ViewRecoveryItem;
