import styled from 'styled-components'
import colors from '../../assets/style/colors'

const DownloadStyle = styled.div`
  min-height: 100vh;
  padding: 20px;
  /* background-image: url("https://img.freepik.com/free-vector/flat-design-geometric-shapes-background_23-2148366514.jpg?w=2000"); */
  background-color: #dfdfdf;
  box-sizing: border-box;
  margin: auto;
  .container {
    width: 960px !important;
    margin: auto;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    padding: 20px 20px 20px 80px;
    margin-bottom: 20px;
  }

  .contract {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
  }

  .title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .contract-content {
    font-size: 16px;
  }

  .contract-content p {
    margin: 5px 0;
  }

  .sub-title {
    font-weight: bold;
  }
`
export default DownloadStyle
