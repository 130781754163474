import React, { memo, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { get } from "lodash";
import api from "../../../api";
import { ErrorModal } from "../../../components/Modal";
import CreateUserStyle from "../../../components/Modal/CreateUser/CreateUserStyle";
import RegionsData from "../../../components/Modal/CreateUser/Regions/regions.json";
import Button from "../../../components/Button";
import { useSelector } from "react-redux";
import customMainApi from "../../../api";
import DistrictData from "../../../components/Modal/CreateUser/Regions/districts.json";
import VillageData from "../../../components/Modal/CreateUser/Regions/villages.json";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const UpdateUserModal = (props) => {
  const { getRef = () => {}, onUpdated = () => {}, onClose = () => {} } = props;
  const { getMe } = useSelector((state) => state.main);

  const errorRef = useRef();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [cardCode, setCardCode] = useState("");
  const [score, setScore] = useState("");
  const [katm, setKatm] = useState("");
  const [mib, setMib] = useState("");
  const [nasiya, setNasiya] = useState("");
  const [passport, setPassport] = useState("");
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");
  const [workplace, setWorkplace] = useState("");
  const [address, setAddress] = useState("");
  const [male, setMale] = useState("");
  const [userName, setUserName] = useState("");
  const [userSureName, setUserSureName] = useState("");
  const [userFathersName, setUserFathersName] = useState("");
  const [disData, setDisData] = useState([]);
  const [villData, setVillData] = useState([]);
  const [region, setRegion] = useState("");
  const [district, setDistrict] = useState("");
  const [village, setVillage] = useState("");
  const [passportAdress, setPassportAdress] = useState("");
  const [liveAdress, setLiveAdress] = useState("");
  const [userFields, setUserFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const ref = {
      open: (data) => {
        setIsOpenModal(true);
        setCardCode(
          get(data, "CardCode", null) !== null ? get(data, "CardCode", "") : "",
        );
        setUserName(
          get(data, "CardName", null) !== null
            ? get(data, "CardName", "").split(" ")[1]
            : "",
        );
        setUserFathersName(
          get(data, "CardName", null) !== null
            ? get(data, "CardName", "").split(" ")[2] +
                " " +
                (get(data, "CardName", "").split(" ")[3] || "")
            : "",
        );
        setUserSureName(
          get(data, "CardName", null) !== null
            ? get(data, "CardName", "").split(" ")[0]
            : "",
        );
        setWorkplace(
          get(data, "U_workplace", null) !== null
            ? get(data, "U_workplace", "")
            : "",
        );
        setPhone(
          get(data, "Phone1", null) !== null ? get(data, "Phone1", "") : "",
        );
        setPhone2(
          get(data, "phone2", null) !== null ? get(data, "phone2", "") : "",
        );
        setMale(
          get(data, "U_Gender", null) !== null ? get(data, "U_Gender", "") : "",
        );
        setPassport(
          get(data, "U_PS", null) !== null ? get(data, "U_PS", "") : "",
        );
        setScore(
          get(data, "U_Score", null) !== null ? get(data, "U_Score", "") : "",
        );
        setKatm(
          get(data, "U_KATM", null) !== null ? get(data, "U_KATM", "") : "",
        );
        setMib(get(data, "U_MIB", null) !== null ? get(data, "U_MIB", "") : "");
        setNasiya(
          get(data, "U_Nasiya", null) !== null ? get(data, "U_Nasiya", "") : "",
        );
        setLiveAdress(
          get(data, "BPAddresses[0].Street", null) !== null
            ? get(data, "BPAddresses[0].Street", "")
            : "",
        );
        setVillage(
          get(data, "BPAddresses[0].City", null) !== null
            ? get(data, "BPAddresses[0].City", "")
            : "",
        );
        setDistrict(
          get(data, "BPAddresses[0].County", null) !== null
            ? get(data, "BPAddresses[0].County", "")
            : "",
        );
        setRegion(
          get(data, "BPAddresses[0].StreetNo", null) !== null
            ? get(data, "BPAddresses[0].StreetNo", "")
            : "",
        );
      },
      close: () => setIsOpenModal(false),
    };
    getRef(ref);
  }, []);

  const submit = () => {
    setIsLoading(true);
    let url = `BusinessPartners('${cardCode}')`;
    api
      .patch(url, {
        SalesPersonCode: getMe.SalesPersonCode || -1,
        U_workplace: workplace,
        CardCode: cardCode, //CardCode
        CardName: ` ${userSureName} ${userName} ${userFathersName}`, //UserName, userSurname, userFathersName,
        CardType: "cCustomer", // By default
        GroupCode: 100, // By default
        Phone1: `${phone}`,
        Currency: "##", // Bydefault
        U_Gender: male, // Erkak Ayol
        U_PS: passport, //Pasport
        U_Score: score,
        U_KATM: katm, //Tavsirlab bergan yangi narsam
        U_MIB: mib, //Tavsirlab bergan yangi narsam
        U_Nasiya: nasiya, //Tavsirlab bergan yangi narsam
        BPAddresses: [
          {
            AddressName: "", //Auto generati qilib yuborasiz bir narsa
            Street: liveAdress, //LiveAdress
            City: village, //village
            County: district, //district
            Country: "UZ", //By default
            StreetNo: region, //region
            BPCode: cardCode, //CardCode
          },
        ],
      })
      .then(() => {
        onUpdated();
        setIsOpenModal(false);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      })
      .finally(() => setIsLoading(false));
  };

  const userFieldsFn = () => {
    customMainApi
      .get("UserFieldsMD", {
        params: {
          $filter: "TableName eq 'OCRD'",
        },
      })
      .then((res) => {
        setUserFields(JSON.parse(res.data).value);
      });
  };

  useEffect(() => {
    userFieldsFn();
  }, []);

  const getRegion = (text) => {
    let a = Number(text.split(" - ")[1]);

    let changed_data = DistrictData.filter((v) => {
      return a === Number(get(v, "region_id", 0));
    });
    setDisData(changed_data);
    setRegion(text);
    setDistrict("");
    setVillage("");
  };

  const getDistrict = (text) => {
    let a = Number(text.split(" - ")[1]);

    let changed_data = VillageData.filter((v) => {
      return a === Number(get(v, "district_id", 0));
    });
    setVillData(changed_data);
    setDistrict(text);
    setVillage("");
  };

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => setIsOpenModal(false)}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <CreateUserStyle>
        <div className="card">
          <p className="title">
            {"Создать пользователя"}
            {"!"}
          </p>
          <div className="flex flex-col gap-4">
            <div className="flexCard">
              <input
                type="text"
                placeholder="Имя"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
                }
                defaultValue={userName}
                onChange={(v) => setUserName(v.target.value)}
              />
              <input
                type="text"
                placeholder="Фамилия"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
                }
                defaultValue={userSureName}
                onChange={(v) => setUserSureName(v.target.value)}
              />
              <input
                type="text"
                placeholder="Отчество"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
                }
                defaultValue={userFathersName}
                onChange={(v) => setUserFathersName(v.target.value)}
              />
            </div>
            <div className="flexCard">
              <>
                <input
                  type="text"
                  placeholder="viloyat"
                  list="region"
                  defaultValue={region}
                  onChange={(v) => getRegion(v.target.value)}
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
                  }
                />

                <datalist id="region">
                  {RegionsData.map((v, i) => {
                    return <option key={i} value={`${v.name_uz} - ${v.id}`} />;
                  })}
                </datalist>
              </>
              <>
                <input
                  type="text"
                  placeholder="tuman"
                  list="district"
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
                  }
                  defaultValue={district}
                  onChange={(v) => getDistrict(v.target.value)}
                />

                <datalist id="district">
                  {disData.map((v, i) => {
                    return <option key={i} value={`${v.name_uz} - ${v.id}`} />;
                  })}
                </datalist>
              </>
              <>
                <input
                  type="text"
                  placeholder="Qishloq"
                  list="village"
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full input1'
                  }
                  defaultValue={village}
                  onChange={(v) => setVillage(v.target.value)}
                />

                <datalist id="village">
                  {villData.map((v, i) => {
                    return <option key={i} value={`${v.name_uz} - ${v.id}`} />;
                  })}
                </datalist>
              </>
            </div>
            <div className="flexCard">
              <input
                type="text"
                placeholder="Passport manzili"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
                }
                defaultValue={passportAdress}
                onChange={(v) => setPassportAdress(v.target.value)}
              />

              <input
                type="text"
                placeholder="Yashash manzili"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
                }
                defaultValue={liveAdress}
                onChange={(v) => setLiveAdress(v.target.value)}
              />
            </div>
            <div className="flexCard">
              <input
                type="text"
                placeholder="Код клиента"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
                }
                defaultValue={cardCode}
                onChange={(v) => setCardCode(v.target.value)}
              />

              <input
                type="text"
                placeholder="Паспорт"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
                }
                defaultValue={passport}
                onChange={(v) => setPassport(v.target.value)}
              />
            </div>{" "}
            <div className="flexCard">
              <input
                type="text"
                placeholder="Score"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
                }
                defaultValue={score}
                onChange={(v) => setScore(v.target.value)}
              />
              <label className={"w-full halfInput flex items-center gap-2"}>
                NASIYA :
                <select
                  placeholder="Nasiya"
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 halfInput'
                  }
                  defaultValue={passport}
                  onSelect={(v) => setNasiya(v)}
                >
                  {userFields
                    ?.find((item) => item.Name === "Nasiya")
                    ?.ValidValuesMD.map((value) => (
                      <option value={value.value}>{value.Description}</option>
                    ))}
                </select>
              </label>
            </div>
            <div className="flexCard">
              <label className={"w-full halfInput flex items-center gap-2"}>
                KATM
                <select
                  placeholder="Katm"
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 halfInput'
                  }
                  defaultValue={passport}
                  onSelect={(v) => setKatm(v)}
                >
                  {userFields
                    ?.find((item) => item.Name === "KATM")
                    ?.ValidValuesMD.map((value) => (
                      <option value={value.value}>{value.Description}</option>
                    ))}
                </select>
              </label>

              <label className={"w-full halfInput flex items-center gap-2"}>
                MIB
                <select
                  placeholder="U_MIB"
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 halfInput'
                  }
                  defaultValue={passport}
                  onSelect={(v) => setMib(v)}
                >
                  {userFields
                    ?.find((item) => item.Name === "MIB")
                    ?.ValidValuesMD.map((value) => (
                      <option value={value.value}>{value.Description}</option>
                    ))}
                </select>
              </label>
            </div>
            <div className="flexCard">
              <input
                type="number"
                placeholder="Asosiy telefon raqam"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
                }
                defaultValue={phone}
                onChange={(v) => setPhone(v.target.value)}
              />
              <input
                type="number"
                placeholder="Qo'shimcha telefon raqam"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full halfInput'
                }
                defaultValue={phone2}
                onChange={(v) => setPhone2(v.target.value)}
              />
            </div>
            <input
              type="text"
              placeholder="Рабочее место"
              className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
              defaultValue={workplace}
              onChange={(v) => setWorkplace(v.target.value)}
            />
          </div>

          <div className="flex gap-4 justify-center my-4">
            <div className="flex gap-1">
              <input
                type="radio"
                id="male"
                name="fav_language"
                checked={male === "Male"}
                onChange={(v) => {
                  setMale(v.target.value);
                  console.log(v.target.value);
                }}
                value="Male"
              />

              <label htmlFor="male">Мужчина</label>
            </div>
            <div className="flex gap-1">
              <input
                type="radio"
                id="female"
                name="fav_language"
                checked={male === "Female"}
                onChange={(v) => {
                  setMale(v.target.value);
                  console.log(v.target.value);
                }}
                value="Female"
              />
              <label htmlFor="female">Женщина</label>
            </div>
          </div>

          <div className="centerCard">
            <button
              className="bg-red-500 text-white py-2 px-4 rounded-md btn"
              onClick={() => {
                onClose();
                setIsOpenModal(false);
              }}
            >
              {"Нет"}
            </button>
            <Button
              isLoading={isLoading}
              className={"btn"}
              disabled={
                userName.length <= 0 ||
                userSureName.length <= 0 ||
                userFathersName.length <= 0 ||
                region.length <= 0 ||
                district.length <= 0 ||
                village.length <= 0 ||
                passportAdress.length <= 0 ||
                liveAdress.length <= 0 ||
                cardCode.length <= 0 ||
                passport.length <= 0 ||
                phone.length <= 0 ||
                phone2.length <= 0 ||
                workplace.length <= 0 ||
                male.length <= 0
              }
              onClick={submit}
            >
              {"Да"}
            </Button>
          </div>
        </div>
      </CreateUserStyle>
      <ErrorModal getRef={(r) => (errorRef.current = r)} />
    </Modal>
  );
};

export default memo(UpdateUserModal);
