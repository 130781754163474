import { createSlice } from "@reduxjs/toolkit";

export const cashPayment = createSlice({
  name: "cashPayment",
  initialState: {
    client: "",
    imei: "",
    cardCode: "",
    page: 0,
  },
  reducers: {
    setClient: (state, action) => {
      state.client = action.payload;
    },
    setImei: (state, action) => {
      state.imei = action.payload;
    },
    setCardCode: (state, action) => {
      state.cardCode = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
});
