import React, { useEffect, useRef, useState } from "react";
import CreateProductsStyle from "./CreateProductsStyle";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { get } from "lodash";
import { BsSearch } from "react-icons/bs";
import time from "../../helpers/time";
import {
  ErrorModal,
  SuccessModal,
  WarningModal,
  CreateUser,
} from "../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import ChangeDate from "./ChangeDate";
import { main } from "../../store/slices";
import Layout from "../../components/Layout";
import moment from "moment";
import { roundCurrency } from "../../utils";

const CreateProducts = () => {
  const { setDate } = main.actions;
  const { getMe } = useSelector((state) => state.main);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const changeDateRef = useRef();
  const errorRef = useRef();
  const infoNotEndedRef = useRef();
  const successModalRef = useRef();
  const succesCreateUserRef = useRef();
  const createUserRef = useRef();

  const [customerData, setCustomerData] = useState([]);
  const [productNameData, setProductNameData] = useState([]);
  const [seriaNumberData, setSeriaNumberData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [simpleMonthData, setsimpleMonthData] = useState([]);
  const [vipMonthData, setVipMonthData] = useState([]);

  const [price, setPrice] = useState("");
  const [color, setColor] = useState("");
  const [month, setMonth] = useState("");
  const [monthB2B, setMonthB2B] = useState("");
  const [customer, setCustomer] = useState("");
  const [condition, setCondition] = useState("");
  const [productName, setProductName] = useState("");
  const [seriaNumber, setSeriaNumber] = useState("");
  const [currentPrice, setCurrentPrice] = useState("");
  const [userCardCode, setUserCardCode] = useState("");
  const [seriaNumberMain, setSeriaNumberMain] = useState("");
  const [systemSerialNumber, setSystemSerialNumber] = useState("");
  const [minusedMoney, setMinusedMoney] = useState(0);
  const [productCode, setProductCode] = useState(0);
  const [dateIndex, setDateIndex] = useState(0);
  const [viewData, setViewData] = useState({});
  const [isCash, setIsCash] = useState(false);
  const [firstPayingMoney, setFirstPayingMoney] = useState(0);
  const [isCalculated, setIsCalculated] = useState(false);
  const [isMainLoading, setIsMainLoading] = useState(false);
  const [createUserLoading, setCreateUserLoading] = useState(false);

  useEffect(() => {
    getSimpleMonth();
    getVIPMonth();
  }, []);

  const getSimpleMonth = () => {
    api
      .get("U_INSTCONFBASIC")
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setsimpleMonthData(resData);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(
            JSON.parse(err.response.data),
            "error.message",
            "Не удалось получить данные, попробуйте еще раз.",
          ),
        );
      });
  };

  const getVIPMonth = () => {
    api
      .get("U_INSTCONFVIP")
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setVipMonthData(resData);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(
            JSON.parse(err.response.data),
            "error.message",
            "Не удалось получить данные, попробуйте еще раз.",
          ),
        );
      });
  };

  const searchUser = (a = "") => {
    setCustomer(a);
    api
      .get(
        `BusinessPartners?$select=CardCode,CardName,Address,Phone1,U_Gender,U_PS,BPAddresses&$filter=CardType eq 'cCustomer' and (contains(CardName, '${a}') or contains(U_PS, '${a}') or contains(Phone1, '${a}'))`,
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setCustomerData(resData);
        if (a.includes(" , ")) {
          setUserCardCode(a.split(" , ")[1]);
        }
      })
      .catch((err) => {
        if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
          navigate("/login");
        } else {
          errorRef.current?.open(
            get(
              JSON.parse(err.response.data),
              "error.message",
              "Не удалось получить данные, попробуйте еще раз.",
            ),
          );
        }
      });
  };

  const searchProduct = (a = "") => {
    setProductName(a);
    api
      .get(
        `Items?$select=ItemCode,ItemName,U_Color,U_Condition&$filter=contains(ItemName,'${a}') and QuantityOnStock gt 0`,
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setProductNameData(resData);
        if (resData.length < 2) {
          getSameSerialNumnber(a.split(", ")[1]);
          setProductCode(a.split(", ")[1]);
        }
      })
      .catch((err) => {
        if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
          navigate("/login");
        } else {
          errorRef.current?.open(
            get(
              JSON.parse(err.response.data),
              "error.message",
              "Не удалось получить данные, попробуйте еще раз.",
            ),
          );
        }
      });
  };

  const getSameSerialNumnber = (num) => {
    api
      .get(`SQLQueries('getItem')/List?itemCode='${num}'`, {
        headers: {
          Prefer: "odata.maxpagesize=1000000",
        },
      })
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setSeriaNumberData(resData);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(
            JSON.parse(err.response.data),
            "error.message",
            "Не удалось получить серийный номер. Повторите попытку.",
          ),
        );
      });
  };

  const getOtherInformations = (v) => {
    const num = Number(v.split(") ")[0]) - 1;
    setSeriaNumber(v);
    const code = v.split(") ")[1] || "";
    seriaNumberData.forEach((el, index) => {
      if (get(el, "DistNumber", "") === code) {
        setSystemSerialNumber(get(el, "SysNumber", ""));
      }
    });
    setSeriaNumberMain(v.split(") ")[1]);
    const dataO = seriaNumberData[num];

    setColor(get(dataO, "U_Color", ""));
    setCondition(get(dataO, "U_Condition", ""));
    setCurrentPrice(get(dataO, "CostTotal", ""));
  };

  const getOtherInformationsfromSerialNumber = () => {
    api
      .get(
        `SQLQueries('getItemsBySerialNumber')/List?serial='${seriaNumber}%25'`,
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setProductNameData(resData);
      })
      .catch((err) => {
        if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
          navigate("/login");
        } else {
          errorRef.current?.open(
            get(
              JSON.parse(err.response.data),
              "error.message",
              "Не удалось получить данные, попробуйте еще раз.",
            ),
          );
        }
      });
  };

  const createUserF = (consumer, passport, male, address, phone, cardCode) => {
    setCreateUserLoading(true);
    api
      .post(`BusinessPartners`, {
        CardCode: cardCode,
        CardName: consumer,
        CardType: "cCustomer",
        Phone1: phone,
        U_Gender: male,
        U_PS: passport,
        Currency: "##",
        BPAddresses: [
          {
            AddressName: "Bill To",
            Street: address,
          },
        ],
      })
      .then(() => {
        createUserRef.current?.close();
        succesCreateUserRef.current?.open("Пользователь успешно создан");
      })
      .catch((err) => {
        errorRef.current?.open(
          get(
            JSON.parse(err.response.data),
            "error.message",
            "Не удалось получить данные, попробуйте еще раз.",
          ),
        );
      })
      .finally(() => {
        setCreateUserLoading(false);
      });
  };

  const addTable = () => {
    if (
      customer.length > 0 &&
      productName.length > 0 &&
      seriaNumber.length > 0 &&
      (month.length > 0 || monthB2B.length > 0)
    ) {
      let monthNumber = monthB2B.length > 0 ? monthB2B : month;
      monthNumber = Number(monthNumber.split(" oy")[0]) + 1;

      setTableData([]);

      for (let i = 0; i < monthNumber; i++) {
        let nextMonth = moment().clone().add(i, "months");
        setTableData((pr) => [
          ...pr,
          {
            date: nextMonth.format("YYYY-MM-DD"),
            price: 0,
            price2: 0,
          },
        ]);
      }
      setIsCalculated(true);
    } else {
      infoNotEndedRef.current?.open();
    }
  };

  useEffect(() => {
    if (month.length > 0 || monthB2B.length > 0) {
      addTable();
    }
  }, [month, monthB2B]);

  const ditributeMoney = (money) => {
    setFirstPayingMoney(money);
    let protsent = monthB2B.length > 0 ? monthB2B : month;
    let splitProsent = protsent.split(" oy");

    let whichType = monthB2B.length > 0 ? vipMonthData : simpleMonthData;
    protsent =
      1 +
      Number(
        whichType.find((item) => item.U_Months == splitProsent[0]).U_Percent,
      ) /
        100;

    let sameNumber = roundCurrency(
      (Number(currentPrice) - Number(money)) * protsent,
    );

    let monthMoney = roundCurrency(+sameNumber / (tableData.length - 1));

    setMinusedMoney(Number(money));
    let b = roundCurrency(+sameNumber + Number(money));
    setPrice(b);

    if (Number(money) > 0 && Number(money) < Number(price)) {
      for (let i = 0; i < tableData.length; i++) {
        if (i !== 0) {
          let aa = [...tableData];
          aa[i].price = monthMoney;
          setTableData(aa);
        } else {
          let aa = [...tableData];
          aa[0].price = money;
          setTableData(aa);
        }
      }
    }
  };

  //main create Product

  const checkU_WHSCode = () => {
    if (get(getMe, "U_Warehouse", null) === null) {
      alert("Foydalanuvchiga ombor biriktirilmagan");
    } else {
      createOrder();
    }
  };

  const createOrder = () => {
    const nowDays = new Date();
    let whichType = monthB2B.length > 0 ? vipMonthData : simpleMonthData;

    if (!isCash) {
      let monthNumber = monthB2B.length > 0 ? monthB2B : month || "";
      monthNumber = Number(monthNumber.split(" oy")[0]);

      let protsent = monthB2B.length > 0 ? monthB2B : month || "";
      let splitProsent = protsent.split(" oy");

      protsent =
        1 +
        Number(
          whichType.find((item) => item?.U_Months == splitProsent[0])
            ?.U_Percent,
        ) /
          100;
    }
    setIsMainLoading(true);

    const dataT = tableData.map((v) => {
      return {
        Total: v.price,
        DueDate: v.date,
      };
    });

    let body = {
      CardCode: userCardCode,
      DocDate: time.to(nowDays, "YYYY-MM-DD"),
      DocumentLines: [
        {
          ItemCode: productCode,
          SerialNumbers: [
            {
              InternalSerialNumber: seriaNumberMain,
              SystemSerialNumber: systemSerialNumber,
            },
          ],
          Quantity: 1,
          UnitPrice: price,
          WarehouseCode: get(getMe, "U_Warehouse", ""),
        },
      ],
    };
    if (!isCash) Object.assign(body, { DocumentInstallments: dataT });
    api
      .post(`Invoices`, body)
      .then((res) => {
        const resData = JSON.parse(res.data);
        setViewData({
          Invoices: {
            CardName: get(resData, "CardName", ""),
            DocEntry: get(resData, "DocEntry", 0),
            CardCode: get(resData, "CardCode", 0),
          },
          DocumentLines: {
            ItemDescription: productName,
          },
        });
        successModalRef.current?.open("Заказ успешно создан");
      })
      .catch((err) => {
        setIsMainLoading(false);
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      });
  };

  const changeMainPrice = (v) => {
    let mainPrice = Number(v);
    setPrice(v);

    let dataT = mainPrice;
    let A = (dataT - minusedMoney) / (Number(tableData.length) - 1);

    for (let i = 0; i < tableData.length; i++) {
      if (i !== 0) {
        let aa = [...tableData];
        aa[i].price = A;
        setTableData(aa);
      } else {
        let aa = [...tableData];
        aa[0].price = minusedMoney;
        setTableData(aa);
      }
    }
  };

  const changeDate = (v) => {
    let exampleDate = [...tableData];
    let day = v.slice(8, 10);

    if (dateIndex === 0) {
      exampleDate[0].date = v;
    } else {
      for (let i = 0; i < exampleDate.length; i++) {
        let nextMonth = moment().clone().add(i, "months").format("YYYY-MM-DD");
        let selectedDay = nextMonth.slice(0, 8) + day;
        let specificMonth = moment(nextMonth.slice(0, 7), "YYYY-MM")
          .endOf("month")
          .format("YYYY-MM-DD");
        let maxDay = Number(specificMonth.slice(8, 10));

        if (i >= dateIndex) {
          if (Number(day) >= maxDay) {
            exampleDate[i].date = specificMonth;
          } else {
            exampleDate[i].date = selectedDay;
          }
        }
      }
    }
    setTableData(exampleDate);
    changeDateRef.current?.close();
  };

  const openChangeDate = (date, i) => {
    dispatch(setDate(date));
    changeDateRef.current?.open();
    setDateIndex(i);
  };

  const handleChangeValues = (v) => {
    let aa = [...tableData];
    let total = 0;
    for (let i = 1; i < tableData.length; i++) {
      aa[i].price = v;
      total += Number(v);
    }
    total += +firstPayingMoney;
    setTableData(aa);
    setPrice(total);
  };

  return (
    <Layout>
      <CreateProductsStyle>
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-7 lg:grid-cols-3 items-start">
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 lg:col-span-2">
              <div className="flex gap-2">
                <input
                  type="text"
                  list="client"
                  placeholder="Клиент"
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                  }
                  defaultValue={customer}
                  onChange={(v) => searchUser(v.target.value)}
                />
                <datalist id="client">
                  {customerData.map((v, i) => (
                    <option
                      key={i}
                      value={`${get(v, "CardName", "")} , ${get(
                        v,
                        "CardCode",
                        "",
                      )}`}
                    />
                  ))}
                </datalist>
                <button
                  className="bg-[#0A4D68] text-white px-4 rounded-md"
                  onClick={() => createUserRef.current?.open()}
                >
                  +
                </button>
              </div>

              <div className="relative">
                <input
                  type="text"
                  list="productName"
                  placeholder="Товар"
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                  }
                  defaultValue={productName}
                  onChange={(v) => searchProduct(v.target.value)}
                />
                <datalist id="productName">
                  {productNameData.map((v, i) => (
                    <option
                      key={i}
                      value={`${get(v, "ItemName", "")} , ${get(
                        v,
                        "ItemCode",
                        "",
                      )}`}
                    />
                  ))}
                </datalist>
              </div>

              <div className="flex gap-2">
                <input
                  type="text"
                  list="seriaNumber"
                  placeholder="Серийный номер"
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                  }
                  defaultValue={seriaNumber}
                  onChange={(v) => getOtherInformations(v.target.value)}
                />
                <datalist id="seriaNumber">
                  {seriaNumberData.map((v, i) => (
                    <option
                      key={i}
                      value={`${i + 1}) ${get(v, "DistNumber", "")}`}
                    />
                  ))}
                </datalist>
                <button
                  className="bg-[#0A4D68] text-white px-4 rounded-md"
                  onClick={getOtherInformationsfromSerialNumber}
                >
                  <BsSearch />
                </button>
              </div>

              <input
                type="text"
                placeholder="Цвет"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                }
                defaultValue={color}
                // onChange={(v) => setColor(v.target.value)}
                disabled={true}
              />

              <input
                type="text"
                placeholder="Состояние"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                }
                defaultValue={condition}
                disabled={true}
              />

              <input
                type="number"
                placeholder="Текущая цена"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                }
                onChange={(v) => setCurrentPrice(v.target.value)}
                defaultValue={currentPrice}
              />

              <div className="flex  gap-2">
                <input
                  type="text"
                  list="month"
                  placeholder="Месяц"
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                  }
                  value={month}
                  onChange={(v) => {
                    setMonth(v.target.value);
                    setMonthB2B("");
                    setIsCash(false);
                  }}
                />
                <datalist id="month">
                  {simpleMonthData.map((v, i) => {
                    return <option key={i} value={`${v.U_Months} oy`} />;
                  })}
                </datalist>
                <input
                  type="text"
                  list="monthB2B"
                  placeholder="Месяц B2B"
                  className={
                    'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                  }
                  value={monthB2B}
                  onChange={(v) => {
                    setMonthB2B(v.target.value);
                    setMonth("");
                    setIsCash(false);
                  }}
                />
                <datalist id="monthB2B">
                  {vipMonthData.map((v, i) => {
                    return <option key={i} value={`${v.U_Months} oy`} />;
                  })}
                </datalist>{" "}
                <input
                  type="button"
                  className="px-2 rounded-md"
                  style={{
                    backgroundColor: isCash ? "#0A4D68" : "#b4b4b4",
                    color: "white",
                  }}
                  value={"Наличные"}
                  onClick={() => {
                    setIsCash(true);
                    setMonth("");
                    setMonthB2B("");
                    setMonth("");
                    setPrice("");
                    setMonthB2B("");
                  }}
                />
              </div>

              <input
                type="number"
                placeholder="Цена"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                }
                value={price}
                onChange={(v) => changeMainPrice(v.target.value)}
              />
            </div>

            {isCash ? null : (
              <div>
                <div className="center mb-4">
                  <Button onClick={addTable}>Расчет</Button>
                </div>

                {isCalculated ? (
                  <div className="overflow-y-auto mb-8">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 border">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                        <tr>
                          <th scope="col" className="px-6 py-4">
                            N
                          </th>
                          <th scope="col" className="px-6 py-4">
                            Дата
                          </th>
                          <th scope="col" className="px-6 py-4">
                            Оплата
                          </th>
                          <th scope="col" className="px-6 py-4">
                            Оплаченный
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((v, i) => {
                          return (
                            <tr
                              className="bg-white border-b  hover:bg-gray-50 "
                              key={i}
                            >
                              <td className="px-6 py-4 font-medium text-gray-900 ">
                                {i}
                              </td>
                              <td
                                onClick={() => openChangeDate(v.date, i)}
                                className="px-6 py-4 whitespace-nowrap"
                              >
                                {v.date}
                              </td>
                              <td className="px-6 py-4">
                                {i === 0 && (
                                  <input
                                    type="number"
                                    className={
                                      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                                    }
                                    style={{ minWidth: 100 }}
                                    onChange={(v) =>
                                      ditributeMoney(v.target.value)
                                    }
                                  />
                                )}
                                {i === 1 && (
                                  <input
                                    type="number"
                                    className={
                                      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                                    }
                                    style={{ minWidth: 100 }}
                                    value={v.price}
                                    onChange={(v) =>
                                      handleChangeValues(v.target.value)
                                    }
                                  />
                                )}
                                {i !== 0 && i !== 1 && Number(v.price)}
                              </td>
                              <td className="px-6 py-4">{v.price2}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : null}
              </div>
            )}
          </div>

          <div className="flex gap-3 mt-8 justify-end w-full">
            <Button
              btnStyle={{ backgroundColor: "red" }}
              onClick={() => navigate("/allProducts")}
            >
              Назад
            </Button>
            <Button onClick={checkU_WHSCode} isLoading={isMainLoading}>
              Создать
            </Button>
          </div>
        </div>
      </CreateProductsStyle>
      <>
        <CreateUser
          isLoading={createUserLoading}
          getRef={(r) => (createUserRef.current = r)}
          onConfirm={(consumer, passport, male, address, phone, cardCode) =>
            createUserF(consumer, passport, male, address, phone, cardCode)
          }
        />
        <ErrorModal
          getRef={(r) => (errorRef.current = r)}
          onConfirm={() => {
            setIsMainLoading(false);
          }}
        />
        <SuccessModal
          getRef={(r) => {
            succesCreateUserRef.current = r;
          }}
        />
        <SuccessModal
          getRef={(r) => {
            successModalRef.current = r;
          }}
          onConfirm={() => navigate("/viewItem", { state: viewData })}
        />

        <ChangeDate
          getRef={(r) => {
            changeDateRef.current = r;
          }}
          onConfirm={(v) => changeDate(v)}
        />

        <WarningModal
          getRef={(ref) => {
            infoNotEndedRef.current = ref;
          }}
        />
      </>
    </Layout>
  );
};

export default CreateProducts;
