import React, { useEffect, useRef, useState } from "react";
import { ErrorModal, SuccessModal, WarningModal } from "../../components/Modal";
import Progressbar from "../../components/Progress";
import { json, useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import Button from "../../components/Button";
import RecoveryStyle from "./RecoveryStyle";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import api, { customFuncsApi } from "../../api";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import { recovery as RecoveryStore } from "../../store/slices";

const Recovery = () => {
  const { getMe } = useSelector((state) => state.main);
  const dispatch = useDispatch();
  const {
    setName,
    setImei,
    setStartDate,
    setEndDate,
    setStatus: setStatusStore,
    setPage: setPageStore,
  } = RecoveryStore.actions;
  const {
    name,
    imei: imeiStore,
    startDate,
    endDate,
    status: statusStore,
    currentPage: pageStore,
  } = useSelector((state) => state.recovery);

  const navigate = useNavigate();

  const startOfMonth = moment().clone().startOf("month");
  const endOfMonth = moment().clone().endOf("month");
  const startDateFormatted = startOfMonth.format("YYYY-MM-DD");
  const endDateFormatted = endOfMonth.format("YYYY-MM-DD");

  const errorRef = useRef();
  const successRef = useRef();
  const warningRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [isProtsent, setIsProtsent] = useState(false);

  const [status, setStatus] = useState(statusStore);
  const [clientName, setClientName] = useState(name);
  const [imei, setIMEI] = useState(imeiStore);
  const [userCardCode, setUserCardCode] = useState("");
  const [dateStart, setDateStart] = useState(startDate || startDateFormatted);
  const [dateEnd, setDateEnd] = useState(endDate || endDateFormatted);

  const [executer, setExecuter] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [doceAndInsData, setDoceAndInsData] = useState([]);
  const [progress, setProgress] = useState(0);

  const [currentPage, setCurrentPage] = useState(pageStore);
  const [data, setData] = useState({
    data: [],
  });
  const [businessLoading, setBusinessLoading] = useState(false);
  useEffect(() => {
    recovery(dateStart, dateEnd);
    getAllGetterMoney();
    doceAndIns();
    if (pageStore === undefined) {
      dispatch(setPageStore(0));
    }
  }, []);

  const getAllGetterMoney = () => {
    api
      .get(
        `$crossjoin(EmployeesInfo, Departments)?$filter=Departments/Name eq 'Undiruv' and EmployeesInfo/Department eq Departments/Code&$expand=EmployeesInfo($select=EmployeeID,LastName,FirstName,U_CashAccount,U_CardAccount,U_TransAccount),Departments($select=Name,Code)`,
        {
          headers: {
            Prefer: "odata.maxpagesize=90000",
          },
        },
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setExecuter(resData);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      });
  };

  const doceAndIns = () => {
    api
      .get(
        `SQLQueries('latePaymentsMin')/List?date1='${startDateFormatted}T00:00:00Z'&date2='${endDateFormatted}T00:00:00Z'`,
        {
          headers: {
            Prefer: "odata.maxpagesize=90000",
          },
        },
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setDoceAndInsData(resData);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
      });
  };

  const recovery = (startD, endD) => {
    setIsLoading(true);
    customFuncsApi
      .get(
        `installments?date1='${startD}T00:00:00Z'&date2='${endD}T00:00:00Z'${clientName.split(" , ")[1] ? `&CardCode='${clientName.split(" , ")[1]}'` : ""}${
          get(getMe, "Department2.Name", "") === "Boshqaruv"
            ? ""
            : `&U_Employee=${get(getMe, "EmployeeID", "")}`
        }${status?.length <= 0 ? "" : `&status=${status}`}&$skip=${currentPage}${imei !== "" ? `&IntrSerial=%25${imei}%25` : ""}`,
        {
          headers: {
            Prefer: "odata.maxpagesize=10",
          },
        },
      )
      .then((res) => {
        if (get(res, "status", 0) === 401)
          navigate("/login", { replace: true });
        const resData = get(res, "data.value", []);
        setData({ data: resData });

        setData({
          data: [...resData],
        });
        setIsLoading(false);
      })
      .catch((err) => {
        errorRef.current?.open(
          `${JSON.stringify(JSON.parse(err.response.data))}`,
        );
        if (get(err, "response.status", 0) === 401)
          navigate("/login", { replace: true });

        setIsLoading(false);
      });
  };

  const newDatas = () => {
    if (data.data.length >= 10) {
      setCurrentPage(currentPage + 10);
      dispatch(setPageStore(currentPage + 10));
    } else {
      alert("boshqa malumot yoq");
    }
  };

  const oldData = () => {
    if (data.data.oldPage < 0) {
      alert("boshqa malumot yoq");
    } else {
      setCurrentPage(currentPage - 10);
      dispatch(setPageStore(currentPage - 10));
    }
  };

  useEffect(() => {
    recovery(dateStart, dateEnd);
  }, [currentPage]);

  const viewItem = (v) => {
    navigate("/viewRecoveryItem", {
      state: {
        data: v,
        executer,
      },
    });
  };

  const searchUser = (a = "") => {
    setClientName(a);
    // setUserCardCode(a.length <= 0 && "");
    setBusinessLoading(true);
    api
      .get(
        `BusinessPartners?$select=CardCode,CardName,Address,Phone1,GroupCode&$filter=CardType eq 'cCustomer' and (contains(CardName, '${a}') or contains(Phone1, '${a}'))`,
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        if (
          !(resData.length === 1) ||
          !a.includes(resData[0].CardName) ||
          !a.includes(resData[0].CardCode)
        )
          setCustomerData(resData);
        if (
          resData.length === 0 &&
          (!a.includes(resData[0].CardName) || !a.includes(resData[0].CardCode))
        ) {
          setCustomerData([{ CardName: "Нет данных", CardCode: "" }]);
        }
      })
      .catch((err) => {
        // errorRef.current?.open(
        //   get(JSON.parse(err.response.data), 'error.message', ''),
        // )
      })
      .finally(() => {
        setBusinessLoading(false);
      });
  };

  // debounce

  useEffect(() => {
    const delay = 1000;
    let timeoutId;
    if (clientName) {
      timeoutId = setTimeout(() => {
        searchUser(clientName);
      }, delay);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [clientName]);

  const handleChange = (e) => {
    const newSearchTerm = e.target.value;
    if (e.target.value?.includes(" , ")) {
      setUserCardCode(e.target.value?.split(" , ")[1]);
    } else {
      setUserCardCode("");
    }
    dispatch(setName(newSearchTerm));
    setClientName(newSearchTerm);
  };

  const distribution = () => {
    distributionMain(0, 50, 1);
    setIsProtsent(true);
  };

  const distributionMain = (currentN, nextN, someN) => {
    const dunners = executer.map((v) => {
      return get(v, "EmployeesInfo.EmployeeID", 0);
    });
    setProgress(0);
    let someData = doceAndInsData.slice(currentN, nextN).map((v, i) => {
      return {
        DocEntry: v.DocEntry,
        InstallmentId: v.InstlmntID,
      };
    });

    customFuncsApi
      .post(
        `assignDunners`,
        {
          installments: someData,
          dunners,
          Origin: window.origin,
        },
        {
          timeout: 1000 * 60,
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded / total) * 100);
            setProgress(+percent);
          },
        },
      )
      .then(() => {
        if (Math.floor(doceAndInsData.length / 50) + 1 > someN) {
          distributionMain(currentN + 50, nextN + 50, someN + 1);
        } else {
          alert("Amaliyot to'liq yuklandi");
          setIsProtsent(false);
        }
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", ""),
        );
        setIsProtsent(false);
      });
  };

  return (
    <Layout>
      <RecoveryStyle>
        <div className="container">
          <div style={{ width: "100%" }}>
            <p className="topTitle mb-8">График сбора</p>
            <div className="flex flex-col xl:flex-row xl:items-center gap-4 mt-10">
              <div className="flex-1 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                {/*<div>*/}
                {/*  <p className={'font-medium text-zinc-600 text-sm mb-1'}>*/}
                {/*    ФИО*/}
                {/*  </p>*/}
                {/*  <input*/}
                {/*    type="text"*/}
                {/*    list="client"*/}
                {/*    className={*/}
                {/*      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'*/}
                {/*    }*/}
                {/*    value={clientName}*/}
                {/*    onChange={handleChange}*/}
                {/*  />*/}

                {/*  <datalist id="client">*/}
                {/*    {customerData.map((v, i) => (*/}
                {/*      <option*/}
                {/*        key={i}*/}
                {/*        value={`${get(v, 'CardName', '')} , ${get(*/}
                {/*          v,*/}
                {/*          'CardCode',*/}
                {/*          '',*/}
                {/*        )}`}*/}
                {/*      />*/}
                {/*    ))}*/}
                {/*  </datalist>*/}
                {/*</div>*/}
                <div className="relative">
                  <p className={"font-medium text-zinc-600 text-sm mb-1"}>
                    Клиент
                  </p>
                  <input
                    className={"border-[1px] border-[#d9d9d9] p-2 rounded-md "}
                    type="text"
                    value={clientName}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  {clientName && (
                    <div className="absolute top-15 left-0 bg-white w-full z-10 border-[1px] border-[#d9d9d9] max-h-[300px] overflow-y-scroll">
                      {businessLoading ? (
                        <ClipLoader size={10} />
                      ) : (
                        customerData.map((v, i) => {
                          return (
                            <div
                              key={i}
                              className="p-2 border-b cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                if (v.CardName === "Нет данных") return;
                                dispatch(
                                  setName(`${v.CardName} , ${v.CardCode}`),
                                );
                                setUserCardCode(v.CardCode);
                                setClientName(`${v.CardName} , ${v.CardCode}`);
                                setCustomerData([]);
                              }}
                            >
                              {v.CardName} , {v.CardCode}
                            </div>
                          );
                        })
                      )}
                    </div>
                  )}
                </div>
                <div>
                  <p className={"font-medium text-zinc-600 text-sm mb-1"}>
                    ИМEИ
                  </p>
                  <input
                    type="text"
                    className={
                      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                    }
                    value={imei}
                    onChange={(e) => {
                      setIMEI(e.target.value);
                      dispatch(setImei(e.target.value));
                    }}
                  />

                  <datalist id="client">
                    {customerData.map((v, i) => (
                      <option
                        key={i}
                        value={`${get(v, "CardName", "")} , ${get(
                          v,
                          "CardCode",
                          "",
                        )}`}
                      />
                    ))}
                  </datalist>
                </div>

                <div>
                  <p className={"font-medium text-zinc-600 text-sm mb-1"}>
                    Дата начала
                  </p>
                  <input
                    type="date"
                    className={
                      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                    }
                    defaultValue={dateStart}
                    onChange={(v) => {
                      setDateStart(v.target.value);
                      dispatch(setStartDate(v.target.value));
                    }}
                  />
                </div>
                <div>
                  <p className={"font-medium text-zinc-600 text-sm mb-1"}>
                    Дата окончания
                  </p>
                  <input
                    type="date"
                    className={
                      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                    }
                    defaultValue={dateEnd}
                    onChange={(v) => {
                      setDateEnd(v.target.value);
                      dispatch(setEndDate(v.target.value));
                    }}
                  />
                </div>
                <div className="flex flex-col">
                  <p className={"font-medium text-zinc-600 text-sm mb-1"}>
                    Статус платежа
                  </p>
                  <select
                    name="payed"
                    id="payed"
                    className={
                      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full flex-1'
                    }
                    defaultValue={status}
                    onChange={(v) => {
                      setStatus(v.target.value);
                      dispatch(setStatusStore(v.target.value));
                    }}
                  >
                    <option value="all">Все</option>
                    <option value="closed">{"Оплачено"}</option>
                    <option value="open">{"Неоплаченный"}</option>
                  </select>
                </div>
              </div>
              <Button
                isLoading={isLoading}
                onClick={() => recovery(dateStart, dateEnd)}
              >
                Поиск
              </Button>
            </div>
            <div className="mt-8 mb-2 flex flex-col md:flex-row max-w-md">
              <div className="flex gap-2 items-center">
                <div className="size-5 bg-red-200"></div>
                <span> {"<"} 60 день</span>
              </div>
              <div className="flex gap-2 items-center">
                <div className="size-5 bg-red-600"></div>
                <span> {"<"} 150 день</span>
              </div>
              <div className="flex gap-2 items-center">
                <div className="size-5 bg-black"></div>
                <span> {">"} 150 день</span>
              </div>
            </div>
            {isLoading ? (
              <ClipLoader
                loading={true}
                size={75}
                aria-label="Loading Spinner"
                className={"loader"}
                data-testid="loader"
              />
            ) : (
              <div className="overflow-y-auto">
                <table className="w-full text-sm text-left  text-gray-700 ">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                      <th scope="col" className="px-6 py-4">
                        Код клиента
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Имя Клиента
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Товар
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Еж месячная оплата
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Дата продажи
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Исполнитель
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Срок
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.data.map((v, i) => {
                      return (
                        <tr
                          key={i}
                          onClick={() => viewItem(v)}
                          className={
                            get(v, "INV6.PaidToDate", 0) ===
                            get(v, "InsTotal", 0)
                              ? "bg-green-200 hover:border hover:border-zinc-400"
                              : moment().diff(v.DueDate, "days") < 60
                                ? "bg-red-200 hover:border hover:border-zinc-400 "
                                : moment().diff(v.DueDate, "days") < 150
                                  ? "bg-red-[600] hover:border hover:border-zinc-400"
                                  : "bg-black color-white hover:border hover:border-zinc-00" +
                                    " "
                          }
                        >
                          <td className="px-6 py-4 font-medium text-gray-900 ">
                            {get(v, "CardCode", "Код покупателя")}
                          </td>
                          <td className="px-6 py-4">
                            {get(v, "CardName", "Имя покупателя")}
                          </td>
                          <td className="px-6 py-4">
                            {get(v, "Dscription", "")}
                          </td>
                          <td className="px-6 py-4">
                            {get(v, "InsTotal", "").slice(0, -4)} USD
                          </td>
                          <td className="px-6 py-4">
                            {moment(get(v, "DueDate", "").slice(0, 10)).format(
                              "DD-MM-YYYY",
                            )}{" "}
                          </td>
                          <td className="px-6 py-4">
                            {get(v, "firstName", "")} {get(v, "lastName", "")}
                          </td>
                          <td className="px-6 py-4">
                            {v.U_PromisedDate
                              ? moment(get(v, "U_PromisedDate", "")).format(
                                  "DD-MM-YYYY",
                                )
                              : ""}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <div className="between">
                  <div className="flex items-center">
                    <Button
                      disabled={currentPage === 0}
                      className={"btn"}
                      onClick={oldData}
                    >
                      {"<"}
                    </Button>
                    <p className={"mr-2"}>{currentPage / 10 + 1}</p>
                    <Button
                      disabled={data.data.length < 10}
                      className={"btn"}
                      onClick={newDatas}
                    >
                      {">"}
                    </Button>
                  </div>
                  {get(getMe, "Department2.Name", "") !== "Undiruv" &&
                  doceAndInsData.length > 1 ? (
                    <Button
                      className="btnSome"
                      onClick={distribution}
                      isLoading={isLoading}
                      btnStyle={{ width: 300 }}
                      hoverBtnStyle={{ width: 300 }}
                    >
                      {"Распределение должников"}
                    </Button>
                  ) : null}
                </div>
              </div>
            )}

            {isProtsent ? (
              <>
                <p className="progres">
                  Пожалуйста, подождите, пока процесс завершится
                </p>

                <Progressbar bgcolor="green" progress={progress} height={23} />
              </>
            ) : null}
          </div>
        </div>
      </RecoveryStyle>
      <>
        <ErrorModal getRef={(r) => (errorRef.current = r)} />
        <SuccessModal getRef={(r) => (successRef.current = r)} />
        <WarningModal getRef={(r) => (warningRef.current = r)} />
      </>
    </Layout>
  );
};

export default Recovery;
