import React, { useEffect, useRef, useState } from 'react'
import ConstantConsumption from './ConstantConsumption'
import Button from '../../../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi } from '../../../../api'
import _, { get } from 'lodash'
import Layout from '../../../../components/Layout'
import { ErrorModal } from '../../../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import ConstantConsumptionStyle from './ConstantConsumption'

const ConstantConsumptionPage = () => {
  const navigation = useNavigate()
  const inputRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [date, setDate] = useState(new Date().toISOString().split('T')[0])
  const errorRef = useRef()
  const [currency, setCurrency] = useState('UZS')
  const [cashAccountsData, setCashAccountsData] = useState([])
  const [spendAccountsData, setSpendAccountsData] = useState([])
  const [formData, setFormData] = useState({
    CardCode: '',
    CashAccount: '',
    CashSum: 0,
    AccountCode: '',
  })
  const [btnLoading, setBtnLoading] = useState(false)
  const [remarks, setRemarks] = useState(null)

  const handleSpendAccountClick = (event) => {
    if (inputRef.current) {
      inputRef.current.value = event.target.innerText
    }
    setFormData((prev) => ({
      ...prev,
      CardCode: event.target.value,
    }))
  }

  const cashAccounts = async () => {
    const data = await api(
      `ChartOfAccounts?$select=Code,Name&$filter=startswith(Code, '5') and ExternalCode eq '1' and (AcctCurrency eq '${currency}' or AcctCurrency eq '%23%23')`,
    ).then((res) => {
      return JSON.parse(res.data).value
    })
    setCashAccountsData(data)
    setFormData((prev) => ({
      ...prev,
      CashAccount: data[0]?.Code,
    }))
  }

  const typeSpend = async () => {
    const data = await api(
      `ChartOfAccounts?$select=Code,Name&$filter=startswith(Code, '94') and ExternalCode eq '1' and (AcctCurrency eq '${currency}' or AcctCurrency eq '%23%23')`,
    ).then((res) => {
      return JSON.parse(res.data).value
    })
    setSpendAccountsData(data)
    setFormData((prev) => ({
      ...prev,
      CardCode: data[0]?.Code,
    }))
  }

  useEffect(() => {
    cashAccounts()
    typeSpend()
  }, [currency])

  const submitForm = async () => {
    setBtnLoading(true)
    await api
      .post(`VendorPayments`, {
        DocDate: date + 'T00:00:00Z',
        DocType: 'rAccount',
        CardCode: formData.CardCode,
        CashAccount: formData.CashAccount,
        Remarks: remarks ? remarks : null,
        DocCurrency: currency,
        CashSum: formData.CashSum,
        PaymentAccounts: [
          {
            AccountCode: formData.CardCode,
            SumPaid: formData.CashSum,
          },
        ],
      })
      .then((res) => {
        navigation('/outgoing-payment')
        return JSON.parse(res.data).value
      })
      .catch((err) => {
        if (isAxiosError(err))
          errorRef.current?.open(
            get(JSON.parse(err.response.data), 'error.message', ''),
          )
      })

    setBtnLoading(false)
  }

  const handleDateChange = (event) => {
    setDate(event.target.value)
  }

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value)
  }

  const handleClickAccountCode = (event) => {
    setFormData((prev) => ({
      ...prev,
      CashAccount: event.target.value,
    }))
  }

  const handleRemarks = (event) => {
    setRemarks(event.target.value)
  }

  return (
    <Layout>
      <ConstantConsumptionStyle>
        <div className="container">
          <p className="productTitle text-xl font-bold text-center py-8">
            Создать постоянные расход
          </p>

          {isLoading ? (
            <ClipLoader
              loading={true}
              size={75}
              aria-label="Loading Spinner"
              className={'loader'}
              data-testid="loader"
            />
          ) : (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-6">
                <div className="flex flex-col">
                  <p className={'font-medium text-zinc-600 text-sm mb-1'}>
                    Currency
                  </p>
                  <select
                    className={
                      'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
                    }
                    onChange={handleCurrencyChange}
                  >
                    <option value="UZS">UZS</option>
                    <option value="USD">USD</option>
                  </select>
                </div>
                <div className="flex flex-col">
                  <p className={'font-medium text-zinc-600 text-sm mb-1'}>
                    Schot
                  </p>
                  <select
                    className={
                      'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
                    }
                    onChange={handleSpendAccountClick}
                  >
                    {spendAccountsData.map((item) => {
                      return (
                        <option key={item.Code} value={item.Code}>
                          {item.Name} - {item.Code}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className="flex flex-col">
                  <p className={'font-medium text-zinc-600 text-sm mb-1'}>
                    Date
                  </p>
                  <input
                    className={
                      'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
                    }
                    type="date"
                    defaultValue={date}
                    onChange={handleDateChange}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:grid-cols-3">
                <div>
                  <p className={'font-medium text-zinc-600 text-sm mb-1'}>
                    Сумма
                  </p>
                  <input
                    className={
                      'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full'
                    }
                    type="number"
                    onChange={(event) =>
                      setFormData((prev) => ({
                        ...prev,
                        CashSum: event.target.value,
                      }))
                    }
                  />
                </div>
                <div>
                  <p className={'font-medium text-zinc-600 text-sm mb-1'}>
                    Шот
                  </p>
                  <select
                    className={
                      'border-[1px] border-[#DFE2E9"] rounded-md w-full'
                    }
                    onChange={handleClickAccountCode}
                  >
                    {cashAccountsData.map((item) => {
                      return (
                        <option key={item.Code} value={item.Code}>
                          {item.Name} - {item.Code}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div>
                  <p className={'font-medium text-zinc-600 text-sm mb-1'}>
                    Comment
                  </p>
                  <textarea
                    className={
                      'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full min-h-[100px]'
                    }
                    onChange={handleRemarks}
                  ></textarea>
                </div>
              </div>
              <div className="flex justify-end mt-5">
                <Button isLoading={btnLoading} onClick={submitForm}>
                  Submit
                </Button>
              </div>
            </div>
          )}
        </div>
      </ConstantConsumptionStyle>
      <>
        <ErrorModal
          getRef={(r) => {
            errorRef.current = r
          }}
        />
      </>
    </Layout>
  )
}

export default ConstantConsumptionPage
