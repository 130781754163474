import styled from 'styled-components'
import colors from '../../../assets/style/colors'

const CreateUserStyle = styled.div`
  width: 700px;
  .card {
    background-color: #00000000;
    padding: 10px 30px;
    border-radius: 5px;
    text-align: center;
    .input {
      border: 2px solid ${colors.gray};
      font-size: 15px;
      padding: 10px;
      border-radius: 5px;
      color: ${colors.mainColor};
      width: 90%;
      margin-bottom: 15px;
    }
  }
  .flexCard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .input1 {
    width: 33%;
  }
  .title {
    font-size: 20px;
    font-weight: 700;
    color: ${colors.mainColor};
    margin-bottom: 20px;
  }
  .halfInput {
    width: 49.5%;
  }
  .centerCard {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .btn {
    width: 100px;
  }
  .btnY {
    padding: 10px 30px;
    border-radius: 5px;
    border: 3px solid ${colors.mainColor};
    color: ${colors.mainColor};
    transition: 0.2s;
    font-weight: 600;
    font-size: 17px;
  }
  .btnY:hover {
    background-color: ${colors.mainColor};
    color: white;
  }

  .btnN {
    padding: 10px 30px;
    border-radius: 5px;
    border: 3px solid red;
    color: red;
    transition: 0.2s;
    font-weight: 600;
    font-size: 17px;
  }
  .btnN:hover {
    background-color: red;
    color: white;
  }
  .radioBtnCard {
    width: 150px;
    text-align: left;
    margin: auto;
  }
`
export default CreateUserStyle
