import React, { useRef, useState } from 'react'
import DollarCourseStyle from './DollarCourseStyle'
import Button from '../../components/Button'
import api from '../../api'
import Layout from '../../components/Layout'
import { ErrorModal, SuccessModal } from '../../components/Modal'
import { get } from 'lodash'
import moment from 'moment'

const DollarCourse = () => {
  const formattedDate = moment().format('YYYY-MM-DD')

  const successRef = useRef()
  const errorRef = useRef()

  const [isLoading, setIsLoading] = useState(false)
  const [dollar, setDollar] = useState('')
  const [dateC, setDatec] = useState(formattedDate)

  const createDollarCourse = () => {
    setIsLoading(true)
    api
      .post(`SBOBobService_SetCurrencyRate`, {
        Currency: 'UZS',
        Rate: dollar, // kurs
        RateDate: dateC, // sana
      })
      .then(() => {
        setIsLoading(false)
        successRef.current?.open('Курс доллара успешно создан')
      })
      .catch((err) => {
        setIsLoading(false)
        errorRef.current?.open(
          get(JSON.parse(err.response.data), 'error.message', ''),
        )
      })
  }

  return (
    <Layout>
      <DollarCourseStyle>
        <div className="container">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-end gap-4">
            <div>
              <p className={'font-medium text-zinc-600 text-sm mb-1'}>Доллар</p>
              <input
                type="number"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                }
                defaultValue={dollar}
                onInput={(v) => setDollar(v.target.value)}
              />
            </div>

            <div>
              <p className={'font-medium text-zinc-600 text-sm mb-1'}>Дата</p>
              <input
                type="date"
                className={
                  'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
                }
                defaultValue={dateC}
                onInput={(v) => setDatec(v.target.value)}
              />
            </div>

            <Button
              isLoading={isLoading}
              onClick={createDollarCourse}
              btnStyle={{ width: 150 }}
              hoverBtnStyle={{ width: 150 }}
            >
              Добавить
            </Button>
          </div>
        </div>
      </DollarCourseStyle>
      <SuccessModal getRef={(r) => (successRef.current = r)} />
      <ErrorModal getRef={(r) => (errorRef.current = r)} />
    </Layout>
  )
}

export default DollarCourse
