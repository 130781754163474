import React, { useEffect } from 'react'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import DrawerMenuStyle from './DrawerMenuStyle'
import { useLocation, useNavigate } from 'react-router-dom'
import { AiOutlineUnorderedList } from 'react-icons/ai'
import { get } from 'lodash'
import { useSelector } from 'react-redux'

const DrawerMenu = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [isOpen, setIsOpen] = React.useState(false)
  const [activeParent, setActiveParent] = React.useState()
  const [activeMenu, setActiveMenu] = React.useState(location.pathname.slice(1))
  const { getMe } = useSelector((state) => state.main)
  const data =
    get(getMe, 'Department2.Name', '') === 'Sotuv'
      ? [
          {
            title: 'Продажи',
            navigate: 'allProducts',
          },
          {
            title: 'Курс доллара',
            navigate: 'dollarCourse',
          },
          {
            title: 'Клиенты',
            navigate: 'clients',
          },
          {
            title: 'Выход',
            navigate: 'login',
          },
        ]
      : get(getMe, 'Department2.Name', '') === 'Undiruv'
        ? [
            {
              title: 'Сборы',
              navigate: 'recovery',
            },
            // {
            //   title: 'Отчет о сборе',
            //   navigate: 'recoveryChart',
            // },
            {
              title: 'Курс доллара',
              navigate: 'dollarCourse',
            },
            {
              title: 'Клиенты',
              navigate: 'clients',
            },
            {
              title: 'Выход',
              navigate: 'login',
            },
          ]
        : get(getMe, 'Department2.Name', '') === 'Sherik'
          ? [
              {
                title: 'Продажи (партнер)',
                navigate: 'all-products-partner',
              },
              {
                title: 'Выход',
                navigate: 'login',
              },
            ]
          : [
              {
                title: 'Главная страница',
                navigate: 'dashboard',
              },
              {
                title: 'Продажи',
                key: 'allProducts',
                children: [
                  {
                    title: 'Наличный платеж',
                    navigate: 'cash-payment',
                  },
                  {
                    title: 'Рассрочка',
                    navigate: 'installment-plan',
                  },
                ],
              },
              {
                title: 'Сборы',
                navigate: 'recovery',
              },
              {
                title: 'Отчет о сборе',
                navigate: 'recoveryChart',
              },
              {
                title: 'Курс доллара',
                navigate: 'dollarCourse',
              },
              {
                title: 'Клиенты',
                navigate: 'clients',
              },
              {
                title: 'SMS',
                navigate: 'sms',
              },
              {
                title: 'Касса ',
                key: 'cash',
                children: [
                  {
                    title: 'Исходящий платеж',
                    navigate: 'outgoing-payment',
                  },
                  {
                    title: 'Отчет движения по кассе',
                    navigate: 'cash-flow-report',
                  },
                  {
                    title: 'Отчет ДДС',
                    navigate: 'dds-report',
                  },
                ],
              },
              {
                title: 'Покупки ',
                navigate: 'purchases ',
              },
              {
                title: 'Настройки ',
                navigate: 'settings',
              },
              {
                title: 'Выход',
                navigate: 'login',
              },
            ]

  const handleActive = (n) => {
    data.map((v) => {
      if (v.key === n) {
        setActiveParent(n)
        v.children.map((child) => {
          if (child.navigate === n) {
            setActiveMenu(n)
          }
        })
      } else if (v.navigate === n) {
        setActiveMenu(n)
      }
    })
  }

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }

  const goToScreen = (n) => {
    if (n === 'login') {
      navigate(`/${n}`, { replace: true })
    } else {
      navigate(`/${n}`)
    }
  }

  function handleParent(key) {
    if (key === activeParent) {
      setActiveParent(null)
    } else {
      setActiveParent(key)
    }
  }

  useEffect(() => {
    data.map((v) => {
      if (v.children) {
        v.children.map((child) => {
          if (child.navigate === activeMenu) {
            setActiveParent(v.key)
          }
        })
      }
    })
  }, [])

  return (
    <DrawerMenuStyle>
      <button onClick={toggleDrawer} className="listBtn">
        <AiOutlineUnorderedList />
      </button>
      <Drawer open={isOpen} onClose={toggleDrawer} direction="left">
        <div className="drawer">
          <p className="menuTitle">Меню</p>

          {data.map((v, i) => {
            if (v.children)
              return (
                <div>
                  <button
                    className={
                      v.key === activeParent ? 'btnParentActive' : 'btn'
                    }
                    onClick={() => handleParent(v.key)}
                  >
                    {v.title} {activeParent !== v.key ? '>' : '<'}
                  </button>
                  {activeParent === v.key &&
                    v.children.map((child, i) => (
                      <button
                        key={i}
                        className={
                          activeMenu === child.navigate ? 'btnActive' : 'btn'
                        }
                        onClick={() => {
                          goToScreen(child.navigate)
                          handleActive(child.navigate)
                          handleActive(v.key)
                        }}
                      >
                        {child.title}
                      </button>
                    ))}
                </div>
              )
            else
              return (
                <button
                  key={i}
                  className={v.navigate === activeMenu ? 'btnActive' : 'btn'}
                  onClick={() => {
                    goToScreen(v.navigate)
                    handleActive(v.navigate)
                  }}
                >
                  {v.title}
                </button>
              )
          })}
        </div>
      </Drawer>
    </DrawerMenuStyle>
  )
}

export default DrawerMenu
